import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
      style={{
        backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2Fback2.png?alt=media&token=33664944-6b94-4d5f-94e6-b7852110fedf)",
        backgroundSize: '101%',
        backgroundRepeat: 'repeat-y', 
        overflow: "hidden"
      }}
    >
      <Helmet>
        <title>Capture Colombia Tours</title>
      </Helmet>
      {/* SECTION HERO */}
      <div>
        <SectionHeroArchivePage currentPage="Stays" currentTab="Stays" />
      </div>

      {/* SECTION */}
      <div className="pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none" style={{marginTop: "40px", padding: "10px"}}>
        <SectionGridHasMap />
      </div>
 
    </div>
  );
};

export default ListingStayMapPage;
