import React, { Fragment, useState, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import Swal from 'sweetalert2';
import "./StayDatesRangeInput.css"


export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {

  

  let fecha_sel = localStorage.getItem("fecha_seleccionada")

  if(fecha_sel == null)
  {

    const year = new Date().getFullYear();
    const month = String(new Date().getMonth() + 1).padStart(2, '0');
    const day = String(new Date().getDate()).padStart(2, '0');
  

    fecha_sel = year+"-"+month+"-"+day;
  }

  const [year, month, day] = (fecha_sel+"").split('-');
  let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  const [startDate, setStartDate] = useState<Date | null>( date_selec == null ? new Date() : date_selec );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [openfield, setOpenfield] = useState(true);



  //

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if(start != null)
    {
      if(start >= new Date()){

        setStartDate(start);
        setEndDate(end);
      }else
      {
        Swal.fire({
          title: 'Atencion',
          text: 'No puede escoger una fecha menor a la actual',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  };

  const renderInput = () => {

     
    return (
      <>
        <div style={{color: "#e83586"}}>
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
        <span style={{fontWeight: "900", marginRight: "5px"}} >
            {startDate?.toLocaleDateString("en-US", {
              day: "2-digit",
            })}
          </span>
          <span style={{fontWeight: "900"}} >
            {startDate?.toLocaleDateString("en-US", {
              month: "long",
            }) || "Add dates"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none" style={{color: "black"}}>
            {"Select Date"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          {open && (
            <div 
            className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white"
            style={{background: "#a8a6a6", height: "50px", borderRadius: "20px"}}
            ></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel 
            className="positionDateRange absolute left-2/3 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div 
              className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 p-8"
              style={{background: "#c5c5c5", color: "black"}}
              >
              <DatePicker
                selected={startDate}
                onChange={(date)=>{
                   setStartDate(date);
                   if(date != null)
                   {

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                  
                    localStorage.setItem("fecha_seleccionada", `${year}-${month}-${day}`)
      
                                        const diaSemana = date.getDay();

                                        // Convertir el número del día de la semana a un nombre
                                        let nombreDiaSemana;
                                        switch (diaSemana) {
                                          case 0:
                                            nombreDiaSemana = 'Domingo';
                                            break;
                                          case 1:
                                            nombreDiaSemana = 'Lunes';
                                            break;
                                          case 2:
                                            nombreDiaSemana = 'Martes';
                                            break;
                                          case 3:
                                            nombreDiaSemana = 'Miercoles';
                                            break;
                                          case 4:
                                            nombreDiaSemana = 'Jueves';
                                            break;
                                          case 5:
                                            nombreDiaSemana = 'Viernes';
                                            break;
                                          case 6:
                                            nombreDiaSemana = 'Sabado';
                                            break;
                                          default:
                                            nombreDiaSemana = 'Día inválido';
                                        }

                                        localStorage.setItem("dia_seleccionado",nombreDiaSemana+"");                                      
                                     
                                      }
                                      close();
                }}
                startDate={startDate}
                calendarClassName="custom-calendar"
                monthsShown={1} // Mostrar solo un mes
                minDate={new Date()} // Deshabilitar fechas anteriores a la fecha actual
                showPopperArrow={false}
                inline
                 renderDayContents={(day, date) => (
                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                )}
              />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
