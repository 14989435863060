import React from "react";
import { useState } from "react";

import { ReactNode } from "react";
import "./Heading2.css"

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = localStorage.getItem("tour_title") !== null ?
  "Tour " + localStorage.getItem("tour_title")
  :
  "Chose your best option"
  ,
  subHeading,
}) => {

  let local_tour =  localStorage.getItem("tour");
  let cantidad =  localStorage.getItem("cantidad_paquetes_fecha");
  let url = localStorage.getItem("urlQuery");
  let informacion = localStorage.getItem(local_tour+"")
  let informacionJSON = JSON.parse(informacion+"");

  return (
    <div className={`mb-12 lg:mb-16 ${className}`} style={{display: "flex", justifyContent: "center", margin: "100px 0"}}>
      <h2 
      className="FontTitle font-semibold"
      style={{borderBottom: "3px solid #fff741", zIndex: "9999"}}
      >{heading}</h2>

    </div>
  );
};

export default Heading2;


// {subHeading ? (
//   subHeading
// ) : (
//   <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
//     {(informacionJSON && url === "false") && informacionJSON.paquetes.length +" packages" }  
//     {(cantidad && url === "true") && cantidad +" packages" }  
//     {localStorage.getItem("dia_seleccionado")}
//     <span className="mx-2"></span>
//   </span>
// )}