import React, { FC ,useEffect } from "react";
import { useState } from "react";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Swal from 'sweetalert2'

import { useLocation, useParams  } from 'react-router-dom';
import queryString from 'query-string';
import axios, { AxiosRequestConfig } from 'axios';
import "./SectionHero.css";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  
  let informacion = localStorage.getItem(parsed.tour+"")
  let informacionJSON = JSON.parse(informacion+"");

  const [imageTour, setimageTour] = useState(informacionJSON == null || informacionJSON.imagenes.length  == 0 ? null : informacionJSON?.imagenes[0]?.image);

  const [titleTour, setTitleTour] = useState(informacionJSON == null ? "" : informacionJSON.tour.name);
  const [descriptionTour, setDescriptionTour] =  useState(informacionJSON == null ? "" : informacionJSON.tour.description);

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem("lastPath", "null");
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = fechaActual.getMonth() + 1;
    const day = fechaActual.getDate();
    let fecha = `${year}-${(month.toString().length == 1) ? "0" : ""}${month}-${day.toString().length == 1 ? "0" : ""}${day}`;
    console.log("fecha", fecha)
    localStorage.setItem("fecha_seleccionada", fecha);
    // if(Object.keys(parsed).length !==  0){
      localStorage.setItem("urlQuery", "true");
    // }
    const fetchData = async () => {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: 'https://capturecolombia.azurewebsites.net/api/app/base/'+parsed.tour+'/tour-byid',
        headers: {}
      };

      try {
        const response = await axios.request(config);
        let tourfinal = response.data.tour;

        if(tourfinal != null)
        {

          setTitleTour(tourfinal.name);
          localStorage.setItem("tour_title",tourfinal.name);
          setDescriptionTour(tourfinal.description);
          localStorage.setItem("tour_description",tourfinal.description);
          localStorage.setItem("urlQuery", "false");


          try{
              setimageTour( response.data.imagenes[0].image)
          
          }catch(erd){
          
          }

          localStorage.setItem("tour",parsed.tour+"");
          localStorage.setItem(parsed.tour+"",JSON.stringify(response.data));

        }

      } catch (error) {
        console.log(error);
      }

    };

    fetchData();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const canal = searchParams.get('canal');
  
    if(canal != null)
    {
      localStorage.setItem("canal", canal);
    }
  }, []);


  return (
    <div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <img className="LogoSectionHero Logowidth" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2FLogo-Capture.png?alt=media&token=eca452ce-4af9-45ac-a8d4-d916f5d436c6"/>
        </div>
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          {titleTour !== "" ? 
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            {titleTour}
          </h2> : 
          <h2 className="font-medium text-4xl md:text-5xl xl:text-6xl !leading-[114%] ">
          Capture colombia Tours
          </h2> 
          }
          {descriptionTour !== "" ? 
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {descriptionTour}
          </span> :
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 justifyTexto">
          We are a Young company, with a different vision where more than Travel we want to provide unique, magical and safe experiences in one of the most beautiful countries in the world. From guided tours of the city of eternal spring, to witnessing nature shows such as whales in the Pacific, we are the best option to organize the experience you are looking for, and that your only concern is to Live it and Remember it.
          </span>
          }
         </div>
        {imageTour !== null ?
        
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
        <div className="flex-grow">
          <img style={{width:"86%" ,height:"40%"}} className="w-full" src={imageTour} alt="hero" />
        </div> 
      </div>
      :
      <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
        <div className="flex-grow">
          <img style={{width:"150%"}} className="w-full" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2Fprincipal_capture_tours1.jpg?alt=media&token=aa0ace1b-52df-4baa-8345-f5131573234c" alt="hero" />
        </div>
      </div>
        }
      </div>

      <div className="lg:block z-10 mb-12 lg:mb-0 lg:-mt-60 w-full">
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <HeroSearchForm />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
