import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import FilterContext from "helpers/filter/FilterContext";
import DetailPagetLayout from 'containers/ListingDetailPage/Layout';
import CheckOutPagePageMain from './CheckOutPage';
import { isMobile } from 'react-device-detect';
import CheckoutMovil from './CheckoutMovil';
import axios, { AxiosRequestConfig } from 'axios';
import { Tab } from "@headlessui/react";
import Select, { ActionMeta, SingleValue } from 'react-select';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getCountries, sendWompi, sendEmail, approvedReserve, validateWompi } from "api/backendApi";
import { countryCodes } from "./indicativos";
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillTriangleFill, BsTriangle } from 'react-icons/bs';
import Terminos from "./Terminos";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';
import Spinner from "shared/Spinner/SpinnerFull";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white'
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #374151',
    borderRadius: 20,
    color: 'white',
    '& > div': {
      color: 'white',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2684ff' : '#232323',
    color: 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2684ff' : '#2684ff',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: '#232323',
  }),
};

let valorStr = localStorage.getItem("guest_number")+"";

let guest_number = parseInt(valorStr);

let guest_label = [];
for(let ii = 0 ; ii <  guest_number ; ii++)
{
  guest_label.push("Guest # "+(ii+1));
};

const FormPayCreditCard = () => {
  const [indicativos, setIndicativos] = useState([]);
  const [indicativoSeleccionado, setIndicativoSeleccionado] = useState("+57");
  const [paises, setPaises] = useState([]);
  const [paisesSelect, setPaisesSelect] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [valoresPay, setValoresPay] = useState({
    tipoDocumento: "",
    documento: '',
    nombre: '',
    correo: '',
    telefono: '',
    indicativo: "+57",
    numeroCard: "",
    cvc: ""
});
const [selectedDate, setSelectedDate] = useState(null);
const [terminos, setTerminos] = useState(false);
const [indicativo, setIndicativo] = useState("+57");
const [mostrarTerminos, setMostrarTerminos] = useState(false);
const filterContext = useContext(FilterContext);  
const navigate = useNavigate();

useEffect(() => {
  getCountries().then(res => {
    let options = res.map((el) => {
      return { value: el.name.common, label: el.name.common };
    });
  
    options.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  
    setPaisesSelect(options);
    setPaises(res);
  });
  let indica = countryCodes.map( (el) => {
    return { value: el.code, label: el.name };
  })
  setIndicativos(indica)
}, [])

const handlePopstate = () => {
  filterContext.setPagar(false)
};
window.onpopstate  = handlePopstate;


  let tour_descripcion = localStorage.getItem("tour_description")+"";
  let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";

  if(fecha_sel == null)
  {

    const year = new Date().getFullYear();
    const month = String(new Date().getMonth() + 1).padStart(2, '0');
    const day = String(new Date().getDate()).padStart(2, '0');
  
    fecha_sel = year+"-"+month+"-"+day;
  }

  const [year, month, day] = fecha_sel.split('-');
  let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));


  let valorStr = localStorage.getItem("guest_number")+"";

  let guest_number = parseInt(valorStr);

  let hora_cambio =  localStorage.getItem("hora-cambio")+"";
  let cantidad_personas_cambio =  localStorage.getItem("cantidad-personas-cambio")+"";


  let guest_label = [];
  for(let ii = 0 ; ii <  guest_number ; ii++)
  {
    guest_label.push("Guest # "+(ii+1));
  };

  let tour_package_str =  localStorage.getItem("paquete_seleccionado")
  let tour_package_json  = JSON.parse(tour_package_str+""); 

  let tour =  localStorage.getItem(tour_package_json.tour_id)
  let tour_parse  = JSON.parse(tour+""); 


  const reserveDetails = async (datos) => {
    const config = {
      method: 'post',
      url: 'https://capturecolombia.azurewebsites.net/api/app/base/set-reserva-detail',
      data:datos,
      headers: {}
    };

    // try {
      const response = await axios.request(config);
      let id_codigo = JSON.stringify(response.data);
      return id_codigo;

      console.log("response", response);

    // } catch (error) {
    //   console.log("error: ",error);
    // }

  };

  const handleChangePayCreditCard = (event) => {
    const { name, value } = event.target;
    setValoresPay((prevValores) => {
      return { ...prevValores, [name]: value};
    });
  };

  const validarCorreo = (correo) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(correo);
  };

  const handleTerms = (event) => {
    setTerminos(event.target.checked);
  };

  return (
    <div style={{border: "1px solid white", borderRadius: "10px", padding: "20px", position: "relative"}}>
      {spinner && <Spinner texto="Processing payment" />}
      <div style={{width: "100%", textAlign: "center"}}>        
        <h2 className="text-3xl lg:text-4xl font-semibold">
        Personal details and payment
        </h2>
      </div>

              <div>      
              <div className="mt-6">
                <Tab.Group>
                  <Tab.List className="" style={{marginBottom:"20px", width: "100%", textAlign: "center"}}>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                            selected
                              ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                              : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          }`}
                        >
                          Pay with credit Card
                        </button>
                      )}
                    </Tab>
                    
                  </Tab.List>
    
                  <Tab.Panels>
                  {/* <Tab.Panel className="space-y-5"> */}
                      {/* <div className="space-y-1">
                        <Label> User Wompi </Label>
                        <Input type="email" defaultValue="" />
                      </div>
                      <div className="space-y-1">
                        <Label>Password Wompi </Label>
                        <Input type="password" defaultValue="" />
                      </div> */}
                 
                    {/* </Tab.Panel> */}
                   
                    <div className="formFlex" style={{marginTop: "20px"}}>
                    <div className="formFlexItems" >
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Name on card</label>
                        <input
                          type="text"
                          name={"nombre"}
                          value={valoresPay.nombre}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        />
                      </div>
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Email</label>
                        <input
                          type="text"
                          name={"correo"}
                          value={valoresPay.correo}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        />
                      </div>
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Phone</label>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "45%"}}>
                        <Select 
                          value={{value: indicativo, label: indicativo}} 
                          styles={customStyles} 
                          options={indicativos} 
                          onChange={(selectedOption, actionMeta) => {
                              setIndicativo(selectedOption && selectedOption.value)
                            }
                          } />
                        </div>
                        <input
                          style={{width: "50%"}}
                          type="number"
                          name={"telefono"}
                          value={valoresPay.telefono}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        />
                        </div>
                      </div>
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Type of document</label>
                        <select
                          name={`tipoDocumento`}
                          value={valoresPay.tipoDocumento}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        >
                          <option value="">Choose document type</option>
                          <option value="ID">ID</option>
                          <option value="Passport">Passport</option>
                          <option value="DNI">DNI</option>
                          <option value="CC">CC</option>
                          <option value="NIT">NIT</option>
                        </select>
                      </div>
                    </div>

                    <div className="formFlexItems">
                    <div className="space-y-1" style={{marginBottom: "10px"}}>
                      <label>Document Number</label>
                      <input
                        type="number"
                        name={"documento"}
                        value={valoresPay.documento}
                        onChange={(event) => handleChangePayCreditCard(event)}
                      />
                    </div>
                    <div className="space-y-1" style={{marginBottom: "10px"}}>
                      <label>Card Number</label>
                      <input
                        type="number"
                        name={"numeroCard"}
                        value={valoresPay.numeroCard}
                        onChange={(event) => handleChangePayCreditCard(event)}
                      />
                    </div>
                      <div className="flex space-x-20">
                        <div className="space-y-1 datePickerPay" style={{marginBottom: "10px"}}>
                          <label>Expiration Date</label>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        </div>
                        <div className="space-y-1" style={{marginBottom: "10px"}}>
                          <label>CVC</label>
                          <input
                            type="text"
                            name={"cvc"}
                            value={valoresPay.cvc}
                            onChange={(event) => handleChangePayCreditCard(event)}
                          />
                        </div>
                      </div>
                      <div className="space-y-1" style={{marginTop: "30px", color: "#2684ff"}}>                            
                          <input
                            type="checkbox"
                            checked={terminos}
                            onChange={handleTerms}
                            style={{width: "25px", height: "25px", marginRight: "20px"}}
                          />
                          <label
                          onClick={() => {
                            filterContext.setMostrarTerminos(true);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          style={{cursor: "pointer", fontSize: "18px"}}
                          >Terms and Conditions</label>
                      </div>
                    </div>
                    </div>
    
                  </Tab.Panels>
                </Tab.Group>
                <div 
                className="pt-8"
                style={{display: "flex", justifyContent: "center"}}
                >
                  <ButtonPrimary 
                  onClick={async () => {
                    let reserva = localStorage.getItem("valores");
                    let id_disponibilidad = localStorage.getItem("id_disponibilidad");
                    let dataReserva = JSON.parse(reserva+"");
                    let nacionalidad = localStorage.getItem("nationalilty")
                    
                    try
                    {
                     if(valoresPay.correo.length > 0 &&
                      valoresPay.cvc.length > 0 &&
                      valoresPay.documento.length > 0 &&
                      valoresPay.nombre.length > 0 &&
                      valoresPay.numeroCard.length > 0 &&
                      valoresPay.indicativo.length > 0 &&
                      valoresPay.telefono.length > 0 &&
                      valoresPay.tipoDocumento.length > 0                  
                      )
                     {
                      if(validarCorreo(valoresPay.correo))
                      {
                          if(terminos)
                          {
                            setSpinner(true);
                            let codigos = [];
                            await Promise.all(
                              dataReserva?.elementos.map(async (el) => {
                                let data = {
                                  payform_id: "3a0b48b4-ab1c-c7fc-45c1-d88a8a08a784",
                                  tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "",
                                  date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                                  document_guest: el?.documento,
                                  type_document_guest: el?.tipoDocumento,
                                  name_guest: el?.nombre,
                                  email_guest: el?.correo,
                                  phone_guest: `${el?.indicativo}${el?.telefono}`,
                                  age_guest: parseInt(el?.edad),
                                  remark: el?.observaciones,
                                  gross_amount: (tour_package_json?.price),
                                  tax_amount: 0,
                                  net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                                  status: 0,
                                  country: el?.paisOrigen,
                                  approved: "",
                                  status_transaction: "0",
                                  channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                                  channel_name: ""
                                };
          
                                const fecha = hora_cambio == "0001-01-01T00:00:00" ? new Date(tour_package_json?.date_schedul) : new Date(hora_cambio);
            
                                const hora = fecha.getHours().toString().padStart(2, '0');
                                const minutos = fecha.getMinutes().toString().padStart(2, '0');
                                const horaFormateada = `${hora}:${minutos}`;
          
                                let dataCorreo = {
                                  asunto: "Approved",
                                  nombre: el?.nombre,
                                  email: el?.correo,
                                  telefono: `${el?.indicativo}${el?.telefono}`,
                                  documento: el?.documento,
                                  nombrePaquete: tour_package_json?.tour_package,
                                  descripcion: tour_descripcion,
                                  punto_encuentro: tour_package_json?.meeting_point,
                                  duracion: tour_package_json?.duration,
                                  precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                                  recomendaciones: tour_package_json?.recommendations,
                                  politicas_cancelacion: tour_package_json?.policies_cancellation,
                                  fecha: fecha_sel,
                                  hora: horaFormateada,
                                  cantidad: (localStorage.getItem("guest_number")+"")
                                  // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                                }

                                localStorage.setItem("data_correo", JSON.stringify(dataCorreo));
                                
                                return reserveDetails(data).then((res) => {
                                  codigos.push(res);
                                });
                              })
                            );
                            let nombresReserva = dataReserva?.elementos.map((el) => el.nombre);
                            localStorage.setItem("nombresReserva", nombresReserva.toString());
                            localStorage.setItem("codigos_reserva", JSON.stringify(codigos))
                            let dataVacia = {
                              guestLabel: [],
                              elementos: []
                            }
                            let reserveId = uuidv4();
                            let cantidadWompi = (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+""))
                            let dataWompi = {
                              tarjeta: {
                              number: valoresPay.numeroCard,
                              exp_month: selectedDate ? (selectedDate.getMonth() + 1).toString().length === 1 ?
                                `0${(selectedDate.getMonth() + 1).toString()}` :
                                (selectedDate.getMonth() + 1).toString()
                              : "",
                              exp_year: selectedDate ? selectedDate.getFullYear().toString().slice(2) : "",
                              cvc: valoresPay.cvc,
                              card_holder: valoresPay.nombre
                              },
                              customer_email: valoresPay.correo,
                              request_transaction: {
                                acceptance_token: "",
                                amount_in_cents: cantidadWompi * 100,
                                currency: "COP",
                                customer_email: valoresPay.correo,
                                payment_method: {
                                  type: "CARD",
                                  token: "",
                                  installments: 1
                                },
                                payment_source_id: 0,
                                redirect_url: "https://capturecolombia.azurewebsites.net/paydone",
                                reference: reserveId,
                                customer_data: {
                                  phone_number: `${valoresPay.indicativo}${valoresPay.telefono}`,
                                  full_name: valoresPay.nombre,
                                  legal_id: valoresPay.documento,
                                  legal_id_type: valoresPay.tipoDocumento
                                },
                                shipping_address: {
                                address_line_1: "Calle 50B # 2F2 - 34",
                                  address_line_2: "Apartamento 502, Torre I",
                                  country: "CO",
                                  region: "Atlantico",
                                  city: "Soledad",
                                  name: valoresPay.nombre,
                                  phone_number: `${valoresPay.indicativo}${valoresPay.telefono}`,
                                  postal_code: "111111"
                                }
                              },
                              reserver_detail_id: reserveId
                            }
        
                            try {
                              const res = await sendWompi(dataWompi);
                              console.log("res wonpi", res)
                              if (res.data.status === "PENDING") {
                                setTimeout(async () => {
                                  const validate = await validateWompi(res.data.transaccion.data.id);
                                console.log("res validate", validate)
                            
                                  if (validate.data === "APPROVED") {
                                    codigos.map((item) => {
                                      approvedReserve(item, "Aprobado");
                                    });
                                    let datacorreo =  localStorage.getItem("data_correo");
                                    let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                    sendEmail(dataCorreoEnviar);
                                    let correoCapture = {...dataCorreoEnviar, email: "reserva@capturecolombiatours.com"}
                                    sendEmail(correoCapture);
                                    if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                    localStorage.setItem("Reserva_aprobada", "APPROVED");
                                    setSpinner(false);
                                    const dataJSON = JSON.stringify(dataVacia);
                                    localStorage.setItem("valores", dataJSON);
                                    localStorage.removeItem("theme");
                                    localStorage.removeItem("cupos_disponibles");
                                    localStorage.removeItem("tour_title");
                                    localStorage.removeItem("cantidad_paquetes_fecha");
                                    localStorage.removeItem("lastPath");
                                    localStorage.removeItem("tour_description");
                                    localStorage.removeItem("id_disponibilidad");
                                    localStorage.removeItem("urlQuery");
                                    localStorage.removeItem("data_correo");
                                    localStorage.removeItem("canal");
                                    navigate('/pay-done');
                                    Swal.fire({
                                      title: 'Reserved',
                                      text: 'Your reservation has been approved',
                                      icon: 'success',
                                      confirmButtonText: 'Accept'
                                    });
                                  } else {
                                    codigos.map((item) => {
                                      approvedReserve(item, "Rechazado");
                                    });
                                    let datacorreo =  localStorage.getItem("data_correo");
                                    let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                    let correo_rejected = {...dataCorreoEnviar, asunto: "Rejected", email: "reserva@capturecolombiatours.com"}
                                    sendEmail(correo_rejected);
                                    if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, asunto: "Rejected", email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                    localStorage.setItem("Reserva_aprobada", "REJECTED");
                                    setSpinner(false);
                                    Swal.fire({
                                      title: 'Rejected',
                                      text: 'Your reservation has been rejected, contact 573053409944',
                                      icon: 'error',
                                      confirmButtonText: 'Accept'
                                    });
                                  }
                                  
                                }, 10000);
                              } else {
                                codigos.map((item) => {
                                  approvedReserve(item, "Rechazado");
                                });
                                let datacorreo =  localStorage.getItem("data_correo");
                                    let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                    let correo_rejected = {...dataCorreoEnviar, asunto: "Rejected", email: "reserva@capturecolombiatours.com"}
                                    sendEmail(correo_rejected);
                                    if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, asunto: "Rejected", email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                localStorage.setItem("Reserva_aprobada", "REJECTED");
                                setSpinner(false);
                                const dataJSON = JSON.stringify(dataVacia);
                                localStorage.setItem("valores", dataJSON);
                                localStorage.removeItem("theme");
                                localStorage.removeItem("cupos_disponibles");
                                localStorage.removeItem("tour_title");
                                localStorage.removeItem("cantidad_paquetes_fecha");
                                localStorage.removeItem("lastPath");
                                localStorage.removeItem("tour_description");
                                localStorage.removeItem("id_disponibilidad");
                                localStorage.removeItem("urlQuery");
                                navigate('/pay-done');
                                Swal.fire({
                                  title: 'Rejected',
                                  text: 'Your reservation has been rejected, contact 573053409944',
                                  icon: 'error',
                                  confirmButtonText: 'Accept'
                                });
                              }                        
                          
                            } catch (error) {
                              console.log(error);
                            }
                            

                          }
                          else
                          {
                            Swal.fire({
                              title: 'Terms and Conditions',
                              text: 'You must accept terms and conditions',
                              icon: 'error',
                              confirmButtonText: 'Accept'
                            })
                          }
                      }
                      else
                      {
                        Swal.fire({
                          title: 'Error',
                          text: 'Invalid email',
                          icon: 'error',
                          confirmButtonText: 'Accept'
                        })
                      }                  
                     }
                     else{
                      Swal.fire({
                        title: 'Error',
                        text: 'Please, fill all the information required',
                        icon: 'error',
                        confirmButtonText: 'Accept'
                      })
                     }
                    }
                    catch(err)
                    {
                      Swal.fire({
                        title: 'Error',
                        text: 'Error creating reservation',
                        icon: 'error',
                        confirmButtonText: 'Accept'
                      })
                    }
                  }}
                  ><span style={{fontSize: "30px"}}>Confirm and pay</span></ButtonPrimary>
                </div>
              </div>
    
            </div>
        <Terminos />
    </div>
  );
};

export default FormPayCreditCard;
