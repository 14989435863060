import React from "react";

const Spinner = ({texto}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0, 
        background: "#00000080", 
        zIndex: 99999
      }}
    >
      <div
        style={{
          border: "10px solid #f3f3f3",
          borderTop: "10px solid #3498db",
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          animation: "spin 1s linear infinite",
        }}
      ></div>
      <h2>{texto}</h2>
    </div>
  );
};

export default Spinner;