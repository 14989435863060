import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { NumericFormat } from 'react-number-format';
import { UserIcon, CalendarIcon } from "@heroicons/react/24/outline";
import { BsFillTriangleFill, BsTriangle } from 'react-icons/bs';
import { UsersIcon, MapPinIcon, NoSymbolIcon, ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  // const handlePopstate = () => {
  //   localStorage.clear();
  // };

  // window.onpopstate  = handlePopstate;

  const renderContent = () => {

    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+""); 

    let codigos_reserva_str =  localStorage.getItem("codigos_reserva")
    let codigos_reserva_json  = JSON.parse(codigos_reserva_str+""); 
 

    let tour_str =  localStorage.getItem("tour")
    let tour_json  = JSON.parse( localStorage.getItem(tour_str+"")+""); 

    let nombresReserva =  localStorage.getItem("nombresReserva");

    let reservaAprobada =  localStorage.getItem("Reserva_aprobada")

    let image_show = tour_json.imagenes[0].image +"";

    let fecha_sel = localStorage.getItem("fecha_seleccionada");

    let dolar_str =  localStorage.getItem("precio_dolares");
    let dolar = dolar_str && JSON.parse(dolar_str);

    let hora_cambio =  localStorage.getItem("hora-cambio")+"";

    if(fecha_sel == null)
    {
      const year = new Date().getFullYear();
      const month = String(new Date().getMonth() + 1).padStart(2, '0');
      const day = String(new Date().getDate()).padStart(2, '0');
    
      fecha_sel = year+"-"+month+"-"+day;
    }
  
    const [year, month, day] = (fecha_sel+"").split('-');
    let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  

    const __month = date_selec.toLocaleString('default', { month: 'short' });

    const year1 = new Date().getFullYear();
    const month1 = String(new Date().getMonth() + 1).padStart(2, '0');
    const day1 = String(new Date().getDate()).padStart(2, '0');
  

    let fecha_sel1 = year1+"-"+month1+"-"+day1;

    return (
      <div style={{border: "1px solid white", borderRadius: "10px", padding: "20px", marginTop: "20px", position: "relative"}}>
      <div className="triangulos" >
          <BsFillTriangleFill className="trianguloUno"/>
          <BsFillTriangleFill className="triangulo"/>
          <BsTriangle className="triangulo" style={{color: "#930707"}}/>
      </div>
        {/* ------------------------ */}
        <div className="space-y-6" style={{marginBottom: "50px"}}>
          <div className="flex flex-col sm:flex-row sm:items-center" style={{padding: "0 30px"}}>
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={image_show} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                 {tour_json.tour.name}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {tour_package_json.tour_package}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
        
                <strong>Includes:</strong>
                <div className="includes">
                  {tour_package_json?.includes.split(',').map((el: any, index: number) => {
                    const iconName = el.includes("(") || el.includes(")") ? "chulo.png" : "x_blanca.png";
                    const text = el.includes("(") || el.includes(")") ? el.replace(/[()]/g, '') : el.replace(/[\[\]]/g, '');

                    return (
                      <div key={index} className="includes-item">
                        <img style={{width: "16px"}} src={`https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2F${iconName}?alt=media&token=89210e27-a030-45f6-b4e8-cdabd2911e74`} alt="Icono" />
                        <p style={{paddingLeft: "10px"}}>{text}</p>
                      </div>
                    );
                  })}
                </div>    
              </span>         
            </div>
          </div>

          <div style={{width: "100%", padding: "0 22%"}}>
            <div className="meetingPoint">
              <div className="flex items-start space-x-3">
                <span style={{display: "flex", justifyContent: "center", alignItems: "center"}}><MapPinIcon style={{width: "20px", color: "#fff741"}}/></span>
                <span 
                className="text-sm text-neutral-500 dark:text-neutral-400"
                style={{overflow: "hidden", textOverflow: "ellipsis"}}
                >
                  <strong>Meeting Point: </strong>{tour_package_json?.meeting_point}
                </span>
              </div>

              <div className="flex items-center space-x-3">
                <span style={{display: "flex", justifyContent: "center", alignItems: "center"}}><ClockIcon style={{width: "20px", color: "#fff741", transform: "rotate(270deg)"}}/></span>
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  <strong>Hour: </strong>{hora_cambio == "0001-01-01T00:00:00" ? tour_package_json?.date_schedul.split("T")[1].substring(0, 5) : hora_cambio.split("T")[1].substring(0, 5)}
                </span>
              </div>          
            </div>
          </div>
          
          <div style={{width: "100%", padding: "0 20%"}}>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
            <span><CalendarIcon className="widthIcon" style={{color: "#fff741"}}/></span>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                     {__month +" "+day}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
            <span><UserIcon className="widthIcon" style={{color: "#fff741"}}/></span>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">{localStorage.getItem("guest_number")+ " Guests"} </span>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div style={{width: "100%", padding: "0 10%"}}>
            <div className="border-b border-neutral-200  dark:border-neutral-700"></div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6" style={{width: "100%", marginTop: "50px", padding: "0 10% 20px"}}>
          <div className="flex flex-col space-y-4">
          <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Your reservation has been:</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <p>{reservaAprobada}</p>
              </span>
            </div> 
          <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Guest Names</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <p>{nombresReserva && nombresReserva.replace(",", ", ")}</p>
              </span>
            </div>                
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Reservation code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {codigos_reserva_json.map((el: string, index: number) => <p key={index}>{el.replace(/^"|"$/g, '')}</p>)}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {fecha_sel1}
              </span>
            </div>
            {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="flex-1">Coupon: </span>
            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">{localStorage.getItem("descuento_cupon")}%</span>
            </div>}
            <div className="flex text-neutral-6000 dark:text-neutral-300">
            <span className="flex-1">Total Pesos</span>
            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100"><NumericFormat value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total Dollars</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100"><NumericFormat value={((parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
         
          </div>
        </div>
        <div style={{width: "100%", textAlign: "center", margin: "10px 0"}}>
          <ButtonPrimary onClick={()=>{
            localStorage.clear();
          }}  href="https://www.capturecolombiatours.com/"><span style={{fontSize: "30px"}}>Explore more tours!</span></ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div 
    className={`nc-PayPage ${className}`} 
    data-nc-id="PayPage" 
    style={{
      backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2Fback4.png?alt=media&token=e423cdec-6e60-4197-8140-d607b546ef44)",
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat-y', 
      padding: "0px 10px 20px 30px",
      marginLeft: "-20px"
    }}>
      <div style={{display: "flex", justifyContent: "center", margin: "-60px 0"}}>
        <img className="LogoCheckout Logowidth" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2FLogo-Capture.png?alt=media&token=eca452ce-4af9-45ac-a8d4-d916f5d436c6"/>
      </div>
      <div className="almostReady" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", lineHeight: "1"}}>
          <p>Welcome abroad in this</p>
          <p><span style={{color: "#ff005f"}}> amazing</span> experience!</p>
      </div>
      <div style={{margin: "50px 0 20px", width: "100%", textAlign: "center"}}>
        <h3 className="text-2xl font-semibold">Reservation Details</h3>
      </div>
      {/* <main className="container mt-11 mb-24 lg:mb-32 "> */}
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      {/* </main> */}
    </div>
  );
};

export default PayPage;
