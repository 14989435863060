import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import FilterContext from "helpers/filter/FilterContext";
import DetailPagetLayout from 'containers/ListingDetailPage/Layout';
import CheckOutPagePageMain from './CheckOutPage';
import { isMobile } from 'react-device-detect';
import CheckoutMovil from './CheckoutMovil';

const CheckoutLayout = () => {
    const filterContext = useContext(FilterContext);
  return (
    <DetailPagetLayout>
       {isMobile ? (
       <CheckoutMovil />
      ) : (
        <CheckOutPagePageMain />
      )}
    </DetailPagetLayout>
  );
};

export default CheckoutLayout;
