import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarIcon, ChevronLeftIcon, UserIcon } from '@heroicons/react/24/outline';
import FilterContext from "helpers/filter/FilterContext";
import DetailPagetLayout from 'containers/ListingDetailPage/Layout';
import CheckOutPagePageMain from './CheckOutPage';
import { isMobile } from 'react-device-detect';
import ModalSelectDate from 'components/ModalSelectDate';
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from 'components/ModalSelectGuests';
import { NumericFormat } from 'react-number-format';
import Spinner from "shared/Spinner/SpinnerFull";
import FormPayGuest from './FormPayGuest';
import FormPayCreditCard from './FormPayCreditCard';

const CheckoutMovil = () => {
  const [spinner, setSpinner] = useState(false);
  const filterContext = useContext(FilterContext);

  let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";

  if(fecha_sel == null)
  {

    const year = new Date().getFullYear();
    const month = String(new Date().getMonth() + 1).padStart(2, '0');
    const day = String(new Date().getDate()).padStart(2, '0');
  
    fecha_sel = year+"-"+month+"-"+day;
  }

  const [year, month, day] = fecha_sel.split('-');
  let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  let dolar_str =  localStorage.getItem("precio_dolares");
  let dolar = dolar_str && JSON.parse(dolar_str);

  const renderSidebar = () => {

    
    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+""); 
 

    let tour_str =  localStorage.getItem("tour")
    let tour_json  = JSON.parse( localStorage.getItem(tour_str+"")+""); 

    let image_show = tour_json.imagenes[0].image +"";

    return (
      <div>

        <div className="personalDetailsPayment">
          <div className="perosnalDetailsPaymentItems">
            <div>
              <div>
                <h3 className="titlePersonalDetails">Personal details and payment</h3>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5" style={{width: "100%"}}></div>
              </div> 
              <div style={{width: "100%", textAlign: "center"}}>
                  <h3 className="youtTour">Your Tour:</h3>
              </div>
            </div>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <ModalSelectDate
                renderChildren={({ openModal }) => (
                  <div
                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
        
                  >
                    <div className="flex flex-col">
                      <span style={{width: "100%", textAlign: "center"}} className="text-sm text-neutral-400">Date</span>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <span><CalendarIcon className="widthIcon" style={{color: "#fff741"}}/></span>
                        <span className="mt-1.5 text-lg font-semibold">
                          {converSelectedDateToString([date_selec, null])}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              />

              <ModalSelectGuests
                renderChildren={({ openModal }) => (
                  <div
                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  >
                    <div className="flex flex-col">
                      <span style={{width: "100%", textAlign: "center"}} className="text-sm text-neutral-400">Guests</span>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <span><UserIcon className="widthIcon" style={{color: "#fff741"}}/></span>
                        <span className="mt-1.5 text-lg font-semibold">
                        <span className="line-clamp-1">
                          {   localStorage.getItem("guest_number")+" "+                          
                          "Guests"}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          
          <div className="perosnalDetailsPaymentItems">
            <div className="imgCheckout" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="flex-shrink-0 w-full sm:w-40 widthImgCheckout">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <img
                      alt=""
                      className="absolute inset-0 object-cover"
                      sizes="200px"
                      src={image_show}
                    />
                  </div>
                </div>
                <div style={{marginLeft: "10px"}}>
                    <span className="tituloTourCheckout">
                    {tour_package_json?.tour_package}
                    </span>
                  </div>
            </div>
            <div className="py-5 sm:px-5 space-y-3">
                <span style={{padding: "5px", fontSize: "20px"}}>
                  Includes:
                </span>
                <div className="includes">
                  {tour_package_json?.includes.split(',').map((el, index) => {
                    const iconName = el.includes("(") || el.includes(")") ? "chulo.png" : "x_blanca.png";
                    const text = el.includes("(") || el.includes(")") ? el.replace(/[()]/g, '') : el.replace(/[\[\]]/g, '');

                    return (
                      <div className="includes-item" key={index}>
                        <img style={{width: "16px"}} src={`https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2F${iconName}?alt=media&token=89210e27-a030-45f6-b4e8-cdabd2911e74`} alt="Icono" />
                        <p style={{paddingLeft: "10px"}}>{text}</p>
                      </div>
                    );
                  })}
                </div>        
            </div>
          </div>

        </div>     
        
       
        <div className="flex flex-col space-y-4 personalDetailsPayment" style={{margin: "20px 0"}}>
          <div style={{width: "100%", textAlign: "center"}}>
              <h3 className="titlePriceDetails">Price details</h3>
          </div>

         <div className="priceDetails">
            <div className="priceDetailsItems">
                <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={tour_package_json?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span ><NumericFormat value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>
              <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={dolar_str} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={(parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+""))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 &&  <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon: </span>
                <span>{localStorage.getItem("descuento_cupon")}%</span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 &&<div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={tour_package_json?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+"")) } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={dolar_str} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={((parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+""))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon Discount Pesos</span>
                <span><NumericFormat value={(parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+"")} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon Discount Dollars</span>
                <span><NumericFormat value={(parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+"")} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
                          
            </div>

            <div className="priceDetailsItems borderRight">
            <span>{tour_package_json?.policies_cancellation}</span>
          </div>
         </div>

        <div className="totalCheckout">
            <div className="border-b border-neutral-200 dark:border-neutral-700" style={{marginBottom: "10px"}}></div>
            <div className="flex justify-between font-semibold">
              <span>Total Pesos</span>
              <span><NumericFormat 
              style={{color: "#69aa4c"}}
              value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+""))} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
            <div className="flex justify-between font-semibold">
              <span>Total Dollars</span>
              <span><NumericFormat 
              style={{color: "#69aa4c"}}
              value={((parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+""))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
        </div>

        </div>
      </div>
    );
  };

  return (
    <div>
      {spinner && <Spinner texto="Processing payment" />}
      <div style={{display: "flex", justifyContent: "center", margin: "-60px 0"}}>
            <img className="LogoCheckout Logowidth" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2FLogo-Capture.png?alt=media&token=eca452ce-4af9-45ac-a8d4-d916f5d436c6"/>
      </div>
      <div className="almostReady">
          <p>We are<span style={{color: "#fff741"}}> almost</span> ready!</p>
      </div>
      <main className="mt-11 mb-24 lg:mb-32 flex flex-col" style={{height: "auto"}}>
        <div className="w-full">{renderSidebar()}</div>
       {filterContext.pagar === false && <div className="w-full"><FormPayGuest /></div>}
       {filterContext.pagar && <div className="w-full"><FormPayCreditCard /></div>}
      </main>
    </div>
  );
};

export default CheckoutMovil;
