import { Tab } from "@headlessui/react";
import { UserIcon, CalendarIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState, ChangeEvent, useContext } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { NumericFormat } from 'react-number-format';
import axios, { AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { getCountries, sendWompi, sendEmail, approvedReserve, validateWompi } from "api/backendApi";
import Select, { ActionMeta, SingleValue } from 'react-select';
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import DatePicker from "react-datepicker";
import FilterContext from "helpers/filter/FilterContext";
import './stylesPicker.css';
import Terminos from "./Terminos";
import { countryCodes } from "./indicativos";
import Spinner from "shared/Spinner/SpinnerFull";
import { v4 as uuidv4 } from 'uuid';
import "./CheckOutPage.css"
import { StringMappingType } from "typescript";
import { BsFillTriangleFill, BsTriangle } from 'react-icons/bs';

export interface CheckOutPagePageMainProps {
  className?: string;
}

interface Correo {
  asunto: string,
  nombre: string, 
  email: string, 
  documento: string, 
  nombrePaquete: string, 
  descripcion: string,         
  punto_encuentro: string,
  duracion: string,
  precio: number,
  recomendaciones: string,
  politicas_cancelacion: string,
  fecha: string,
  hora: string, 
  cantidad: string, 
  telefono: string, 
}

interface WompiData {
  tarjeta: {
    number: string;
    exp_month: string;
    exp_year: string;
    cvc: string;
    card_holder: string;
  };
  customer_email: string;
  request_transaction: {
    acceptance_token: string;
    amount_in_cents: number;
    currency: string;
    customer_email: string;
    payment_method: {
      type: string;
      token: string;
      installments: number;
    };
    payment_source_id: number;
    redirect_url: string;
    reference: string;
    customer_data: {
      phone_number: string;
      full_name: string;
      legal_id: string;
      legal_id_type: string;
    };
    shipping_address: {
      address_line_1: string;
      address_line_2: string;
      country: string;
      region: string;
      city: string;
      name: string;
      phone_number: string;
      postal_code: string;
    };
  };
  reserver_detail_id: string;
}

type Elemento = {
  tipoDocumento: string;
  documento: string;
  nombre: string;
  correo: string;
  telefono: string;
  indicativo: string,
  edad: string;
  observaciones: string;
  paisOrigen: string;
};

type payCard = {
  tipoDocumento: string,
  documento: string,
  nombre: string,
  correo: string,
  telefono: string,
  indicativo: string,
  numeroCard: string,
  cvc: string
};

type Reserve = {
  payform_id: string,
  tourdetail_id: string,
  date_reserve: Date,
  document_guest: string,
  type_document_guest: string,
  name_guest: string,
  email_guest: string,
  phone_guest: string,
  age_guest: number,
  remark: string,
  gross_amount: number,
  tax_amount: number,
  net_amount: number,
  status: number,
  country: string,
  approved: string,
  status_transaction: string,
  channel_id: string,
  channel_name: string
};

interface OptionType {
  value: string;
  label: string;
}

const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #374151',
    borderRadius: 20,
    color: 'white',
    '& > div': {
      color: 'white',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2684ff' : '#232323',
    color: 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2684ff' : '#2684ff',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: 'white',
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#232323',
  }),
};

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const [enviado, setEnviado] = useState(false);
  const [paises, setPaises] = useState<any | null>([]);
  const [paisesSelect, setPaisesSelect] = useState<OptionType[]>([]);
  const [indicativos, setIndicativos] = useState<OptionType[]>([]);
  const [indicativoSeleccionado, setIndicativoSeleccionado] = useState<string>("+57");
  const [spinner, setSpinner] = useState<boolean>(false);

  let options: OptionType[] = [];

  let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";

  if(fecha_sel == null)
  {

    const year = new Date().getFullYear();
    const month = String(new Date().getMonth() + 1).padStart(2, '0');
    const day = String(new Date().getDate()).padStart(2, '0');
  
    fecha_sel = year+"-"+month+"-"+day;
  }

  const [year, month, day] = fecha_sel.split('-');
  let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  useEffect(() => {
    let dataVacia = {
      guestLabel: [],
      elementos: []
    }
    const dataJSON = JSON.stringify(dataVacia);
    localStorage.setItem("valores", dataJSON)

    getCountries().then(res => {
      let options = res.map((el: any) => {
        return { value: el.name.common, label: el.name.common };
      });
    
      options.sort((a: any, b: any) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    
      setPaisesSelect(options);
      setPaises(res);
    });
    let indica = countryCodes.map( (el: any) => {
      return { value: el.code, label: el.name };
    })
    setIndicativos(indica)
  }, [])

  const navigate = useNavigate();

  let dolar_str =  localStorage.getItem("precio_dolares");
  let dolar = dolar_str && JSON.parse(dolar_str);

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const handleIndicativo = (value: string) => {
    setIndicativoSeleccionado(value)
  }

  const renderSidebar = () => {

    
    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+"");  

    let tour_str =  localStorage.getItem("tour")
    let tour_json  = JSON.parse( localStorage.getItem(tour_str+"")+""); 
    let tour =  localStorage.getItem(tour_package_json.tour_id)
    let tour_parse  = JSON.parse(tour+""); 

    let image_show = tour_json.imagenes[0].image +"";

    const options = paises.map((el: any, index: number) => ({
      value: el?.name.common,
      label: el?.name.common,
    }));

    return (
      <div>

        <div className="personalDetailsPayment">
          <div className="perosnalDetailsPaymentItems">
            <div>
              <div>
                <h3 className="titlePersonalDetails">Personal details and payment</h3>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5" style={{width: "100%"}}></div>
              </div> 
              <div style={{width: "100%", textAlign: "center"}}>
                  <h3 className="youtTour">Your Tour:</h3>
              </div>
            </div>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <ModalSelectDate
                renderChildren={({ openModal }) => (
                  <div
                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
        
                  >
                    <div className="flex flex-col">
                      <span style={{width: "100%", textAlign: "center"}} className="text-sm text-neutral-400">Date</span>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <span><CalendarIcon className="widthIcon" style={{color: "#fff741"}}/></span>
                        <span className="mt-1.5 text-lg font-semibold">
                          {converSelectedDateToString([date_selec, null])}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              />

              <ModalSelectGuests
                renderChildren={({ openModal }) => (
                  <div
                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  >
                    <div className="flex flex-col">
                      <span style={{width: "100%", textAlign: "center"}} className="text-sm text-neutral-400">Guests</span>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <span><UserIcon className="widthIcon" style={{color: "#fff741"}}/></span>
                        <span className="mt-1.5 text-lg font-semibold">
                        <span className="line-clamp-1">
                          {   localStorage.getItem("guest_number")+" "+                          
                          "Guests"}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          
          <div className="perosnalDetailsPaymentItems">
            <div className="imgCheckout" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="flex-shrink-0 w-full sm:w-40 widthImgCheckout">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <img
                      alt=""
                      className="absolute inset-0 object-cover"
                      sizes="200px"
                      src={image_show}
                    />
                  </div>
                </div>
                <div style={{marginLeft: "10px"}}>
                    <span className="tituloTourCheckout">
                    {tour_package_json?.tour_package}
                    </span>
                  </div>
            </div>
            <div className="py-5 sm:px-5 space-y-3">
                <span style={{padding: "5px", fontSize: "20px"}}>
                  Includes:
                </span>
                <div className="includes">
                  {tour_package_json?.includes.split(',').map((el: any, index: number) => {
                    const iconName = el.includes("(") || el.includes(")") ? "chulo.png" : "x_blanca.png";
                    const text = el.includes("(") || el.includes(")") ? el.replace(/[()]/g, '') : el.replace(/[\[\]]/g, '');

                    return (
                      <div key={index} className="includes-item">
                        <img style={{width: "16px"}} src={`https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2F${iconName}?alt=media&token=89210e27-a030-45f6-b4e8-cdabd2911e74`} alt="Icono" />
                        <p style={{paddingLeft: "10px"}}>{text}</p>
                      </div>
                    );
                  })}
                </div>          
            </div>
          </div>

        </div>     
        
       
        <div className="flex flex-col space-y-4 personalDetailsPayment" style={{margin: "20px 0"}}>
          <div style={{width: "100%", textAlign: "center"}}>
              <h3 className="titlePriceDetails">Price details</h3>
          </div>

         <div className="priceDetails">
            <div className="priceDetailsItems">
                <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={tour_package_json?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span ><NumericFormat value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>
              <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={dolar_str} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={(parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+""))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 &&  <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon: </span>
                <span>{localStorage.getItem("descuento_cupon")}%</span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 &&<div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={tour_package_json?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+"")) } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>          <NumericFormat value={dolar_str} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {"  "} x {localStorage.getItem("guest_number")+" Guests"}</span>
                <span><NumericFormat value={((parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+""))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon Discount Pesos</span>
                <span><NumericFormat value={(parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+"")} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
              {parseInt(localStorage.getItem("descuento_cupon")+"") > 0 && <div style={{width: "100%"}} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Coupon Discount Dollars</span>
                <span><NumericFormat value={(parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+"")} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </div>}
                          
              {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Service charge</span>
                <span>$0</span>
              </div> */}
            </div>

            <div className="priceDetailsItems borderRight">
            {/* <span>Policies Cancellation</span> */}
            <span>{tour_package_json?.policies_cancellation}</span>
          </div>
         </div>

        <div className="totalCheckout">
            <div className="border-b border-neutral-200 dark:border-neutral-700" style={{marginBottom: "10px"}}></div>
            <div className="flex justify-between font-semibold">
              <span>Total Pesos</span>
              <span><NumericFormat 
              style={{color: "#69aa4c"}}
              value={(tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+""))} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
            <div className="flex justify-between font-semibold">
              <span>Total Dollars</span>
              <span><NumericFormat 
              style={{color: "#69aa4c"}}
              value={((parseInt(dolar) * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * parseInt(dolar) / 100) * parseInt(localStorage.getItem("guest_number")+""))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </span>
            </div>
        </div>

        </div>
      </div>
    );
  };


  type MiComponenteProps = {
    guestLabel: string[];
  };

  const MiComponente: React.FC<MiComponenteProps> = ({ guestLabel }) => {
    const [valores, setValores] = useState({
      guestLabel,
      elementos: Array(guestLabel.length).fill({
        tipoDocumento: '',
        documento: '',
        nombre: '',
        correo: '',
        telefono: '',
        indicativo: "",
        edad: '',
        observaciones: '',
        paisOrigen: ''
      }) as Elemento[]
    });
    const [terminos, setTerminos] = useState<boolean>(false);

    const filterContext = useContext(FilterContext);

    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+""); 

    let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";
    let tour_descripcion = localStorage.getItem("tour_description")+"";

    let hora_cambio =  localStorage.getItem("hora-cambio")+"";
    let cantidad_personas_cambio =  localStorage.getItem("cantidad-personas-cambio")+"";

    let tour =  localStorage.getItem(tour_package_json.tour_id)
    let tour_parse  = JSON.parse(tour+""); 
  

    if(fecha_sel == null)
    {
  
      const year = new Date().getFullYear();
      const month = String(new Date().getMonth() + 1).padStart(2, '0');
      const day = String(new Date().getDate()).padStart(2, '0');
    
      fecha_sel = year+"-"+month+"-"+day;
    }
  
    const [year, month, day] = fecha_sel.split('-');
    let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

    const reserveDetails = async (datos: Reserve) => {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: 'https://capturecolombia.azurewebsites.net/api/app/base/set-reserva-detail',
        data:datos,
        headers: {}
      };

      try {
        const response = await axios.request(config);
        let id_codigo = JSON.stringify(response.data);
        return id_codigo;

        console.log("response", response);

      } catch (error) {
        console.log("error: ",error);
      }

    };

const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
  const { name, value } = event.target;
  setValores((prevValores) => {
    const elementosActualizados = prevValores.elementos.map((elemento, i) => {
      if (i === index) {
        return {
          ...elemento,
          [name.split("_")[0]]: value
        };
      }
      return elemento;
    });
    return { ...prevValores, elementos: elementosActualizados };
  });
  let datos = JSON.stringify(valores);
};

const handleChangeSelect = (
  selectedOption: SingleValue<{ value: string; label: string }>,
  actionMeta: ActionMeta<{ value: string; label: string }>,
  name: string,
  index: number 
) => {
  setValores((prevValores) => {
    const elementosActualizados = prevValores.elementos.map((elemento, i) => {
      if (i === index) {
        return {
          ...elemento,
          [name.split("_")[0]]: selectedOption?.value
        };
      }
      return elemento;
    });
    return { ...prevValores, elementos: elementosActualizados };
  });
};

const handleTerms = (event: ChangeEvent<HTMLInputElement>) => {
  setTerminos(event.target.checked);
};

  return (
    <div>

      <Tab.Group>
              <Tab.List className="" style={{marginBottom:"20px", width: "100%", textAlign: "center"}}>
              
              {valores.guestLabel.map((element, index) => (
                                  <Tab 
                                  key={index}
                                  as={Fragment}>
                                    {({ selected }) => (
                                      <button
                                        style={{background: "#fefa9b"}}
                                        className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                                          selected
                                            ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                                            : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                                        }`}
                                      >
                                        {element}
                                      </button>
                                    )}
                                  </Tab>
                ))}

              </Tab.List>
          
      <Tab.Panels>
        {valores.guestLabel.map((element, index) => (
      
          <Tab.Panel className="space-y-5 formFlex" key={index}>
           
          <div className="formFlexItems" style={{marginTop: "20px"}}>
            <div className="space-y-1">
                <label>Type of document</label>
                <select
                  name={`tipoDocumento_${index}`}
                  value={valores.elementos[index]?.tipoDocumento || ''}
                  onChange={(event) => handleChange(index, event)}
                >
                  <option value="">Choose document type</option>
                  <option value="ID">ID</option>
                  <option value="Passport">Passport</option>
                  <option value="DNI">DNI</option>
                  <option value="CC">CC</option>
                  <option value="NIT">NIT</option>
                </select>
              </div>
              <div className="space-y-1">
                <label>Document number</label>
                <input
                  type="number"
                  name={`documento_${index}`}
                  value={valores.elementos[index].documento }
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Full name</label>
                <input
                  type="text"
                  name={`nombre_${index}`}
                  value={valores.elementos[index]?.nombre || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Nationality</label>
                <Select 
                name={`paisOrigen_${index}`} 
                value={{value: valores && valores.elementos[index]?.paisOrigen || '', label: valores && valores.elementos[index]?.paisOrigen || ''}} 
                styles={customStyles} 
                options={paisesSelect} 
                onChange={(selectedOption, actionMeta) =>
                  handleChangeSelect(selectedOption, actionMeta, `paisOrigen_${index}`, index)
                } />
              </div>
          </div>

          <div className="formFlexItems">
            <div className="space-y-1">
                <label>Email</label>
                <input
                  type="email"
                  name={`correo_${index}`}
                  value={valores.elementos[index]?.correo || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Mobile phone</label>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "40%"}}>
                <Select 
                name={`indicativo_${index}`} 
                value={{value: valores && valores.elementos[index]?.indicativo || '', label: valores && valores.elementos[index]?.indicativo || ''}} 
                styles={customStyles} 
                options={indicativos} 
                onChange={(selectedOption, actionMeta) =>
                  handleChangeSelect(selectedOption, actionMeta, `indicativo_${index}`, index)
                } />
                </div>
                <input
                  style={{width: "50%"}}
                  type="number"
                  name={`telefono_${index}`}
                  value={valores.elementos[index]?.telefono || ''}
                  onChange={(event) => handleChange(index, event)}
                />
                </div>
              </div>
              <div className="space-y-1">
                <label>Age</label>
                <input
                  type="number"
                  name={`edad_${index}`}
                  value={valores.elementos[index]?.edad || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Comments</label>
                <textarea
                  name={`observaciones_${index}`}
                  value={valores.elementos[index]?.observaciones || ''}
                  onChange={(event) => handleChange(index, event)}
                  placeholder="Food alergies, etc..."
                />
              </div>
            {tour_package_json?.price === 0 && <div className="space-y-1" style={{marginBottom: "10px", color: "#2684ff"}}>
                              <label
                              onClick={() => {
                                filterContext.setMostrarTerminos(true);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              style={{cursor: "pointer"}}
                              >Terms and Conditions</label>
                              <br />
                              
                              <input
                                type="checkbox"
                                checked={terminos}
                                onChange={handleTerms}
                                style={{display: "block"}}
                              />
              </div>}
          </div>         
          </Tab.Panel>
        ))}
      </Tab.Panels>
      </Tab.Group>
      
      <Tab.Group>
      <div className="pt-8" style={{width: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
      <h2 className="text-2xl lg:text-3xl font-semibold" style={{marginBottom: "20px"}}>
          Reservation
      </h2>
      <div style={{display: "flex", justifyContent: "space-around", width: "60%"}}>
      <ButtonPrimary 
      onClick={async () => {
        localStorage.setItem("valores", JSON.stringify(valores));
        const todosTienenDatos = valores?.elementos.every((registro: Elemento) => {
          return (
            registro.tipoDocumento.length > 0 &&
            registro.documento.length > 0 &&
            registro.nombre.length > 0 &&
            registro.correo.length > 0 &&
            registro.telefono.length > 0 &&
            registro.edad.length > 0 &&
            registro.paisOrigen.length > 0 
          );
        });

        if(todosTienenDatos && tour_package_json?.price < 0)
        {
          filterContext.setPagar(true);
        }
        else if(todosTienenDatos && tour_package_json?.price >= 0)
        {
            let reserva = localStorage.getItem("valores");
            let id_disponibilidad = localStorage.getItem("id_disponibilidad");
            let dataReserva = JSON.parse(reserva+"");
            let nacionalidad = localStorage.getItem("nationalilty")
            const todosTienenDatos = dataReserva?.elementos.every((registro: Elemento) => {
              return (
                registro.tipoDocumento.length > 0 &&
                registro.documento.length > 0 &&
                registro.nombre.length > 0 &&
                registro.correo.length > 0 &&
                registro.telefono.length > 0 &&
                registro.indicativo.length > 0 &&
                registro.edad.length > 0 &&
                registro.paisOrigen.length > 0 
              );
            });
            
            try
            {
             if(todosTienenDatos)
             {
                  // if(terminos)
                  // {
                    setSpinner(true);
                    let codigos:any = [];
                    await Promise.all(
                      dataReserva?.elementos.map(async (el: Elemento) => {
                        let data: Reserve = {
                          payform_id: "3a0b48b4-ab1c-c7fc-45c1-d88a8a08a784",
                          tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "00000000-0000-0000-0000-000000000000",
                          date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                          document_guest: el?.documento,
                          type_document_guest: el?.tipoDocumento,
                          name_guest: el?.nombre,
                          email_guest: el?.correo,
                          phone_guest: `${el?.indicativo}${el?.telefono}`,
                          age_guest: parseInt(el?.edad),
                          remark: el?.observaciones,
                          gross_amount: (tour_package_json?.price),
                          tax_amount: 0,
                          net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          status: 0,
                          country: el?.paisOrigen,
                          approved: "", 
                          status_transaction: "0",
                          channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                          channel_name: ""
                        };
                        console.log("datass", data);
                        const fecha = hora_cambio == "0001-01-01T00:00:00" ? new Date(tour_package_json?.date_schedul) : new Date(hora_cambio);
    
                        const hora = fecha.getHours().toString().padStart(2, '0');
                        const minutos = fecha.getMinutes().toString().padStart(2, '0');
                        const horaFormateada = `${hora}:${minutos}`;
  
                        let dataCorreo: Correo = {
                          asunto: "Approved",
                          nombre: el?.nombre,
                          email: el?.correo,
                          telefono: `${el?.indicativo}${el?.telefono}`,
                          documento: el?.documento,
                          nombrePaquete: tour_package_json?.tour_package,
                          descripcion: tour_descripcion,
                          punto_encuentro: tour_package_json?.meeting_point,
                          duracion: tour_package_json?.duration,
                          precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          recomendaciones: tour_package_json?.recommendations,
                          politicas_cancelacion: tour_package_json?.policies_cancellation || "Cancellation Policies: Does not apply",
                          fecha: fecha_sel,
                          hora: horaFormateada,
                          cantidad: (localStorage.getItem("guest_number")+"")
                          // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                        }
                        console.log("correo", dataCorreo);
                        sendEmail(dataCorreo);
                        let correoCapture = {...dataCorreo, email: "reserva@capturecolombiatours.com"}
                        sendEmail(correoCapture);
                        if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                        {
                          let correoCaptureAlternate = {...dataCorreo, email: tour_parse.tour.alternate_email}
                          sendEmail(correoCaptureAlternate);
                        }
                        return reserveDetails(data).then((res) => {
                          codigos.push(res);
                        });
                      })
                    );
                    let nombresReserva = dataReserva?.elementos.map((el: Elemento) => el.nombre);
                    localStorage.setItem("nombresReserva", nombresReserva.toString());
                    localStorage.setItem("codigos_reserva", JSON.stringify(codigos));
                    codigos.map((item: string) => {
                      approvedReserve(item, "Aprobado")
                    })
                    let dataVacia = {
                      guestLabel: [],
                      elementos: []
                    };
                    localStorage.setItem("Reserva_aprobada", "Approved");
                    const dataJSON = JSON.stringify(dataVacia);
                    localStorage.setItem("valores", dataJSON);
                    localStorage.removeItem("theme");
                    localStorage.removeItem("cupos_disponibles");
                    localStorage.removeItem("tour_title");
                    localStorage.removeItem("cantidad_paquetes_fecha");
                    localStorage.removeItem("lastPath");
                    localStorage.removeItem("tour_description");
                    localStorage.removeItem("id_disponibilidad");
                    localStorage.removeItem("urlQuery");
                    navigate('/pay-done');
                    setSpinner(false);
                    Swal.fire({
                      title: 'Reserved',
                      text: 'Your reservation has been approved',
                      icon: 'success',
                      confirmButtonText: 'Accept'
                    })
                  // }
                  // else
                  // {
                  //   Swal.fire({
                  //     title: 'Terms and Conditions',
                  //     text: 'You must accept terms and conditions',
                  //     icon: 'error',
                  //     confirmButtonText: 'Accept'
                  //   })
                  // }                  
             }
             else{
              setSpinner(false);
              Swal.fire({
                title: 'Erro',
                text: 'Please, fill all the information required',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
             }
            }
            catch(err)
            {
              setSpinner(false);
              Swal.fire({
                title: 'Error',
                text: 'Error creating reservation',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
            }
        }
        else
        {
          Swal.fire({
            title: 'Error',
            text: 'Please, fill all the information required',
            icon: 'error',
            confirmButtonText: 'Accept'
          })
        }

      }}
      >
        <span style={{fontSize: "30px"}}>{tour_package_json?.price >= 0 ? "Reserve" : "Credit Card"}</span>
      </ButtonPrimary>
      {(tour_package_json?.price > 0 && localStorage.getItem("canal") != null) && <ButtonPrimary 
      onClick={async () => {
        localStorage.setItem("valores", JSON.stringify(valores));
        const todosTienenDatos = valores?.elementos.every((registro: Elemento) => {
          return (
            registro.tipoDocumento.length > 0 &&
            registro.documento.length > 0 &&
            registro.nombre.length > 0 &&
            registro.correo.length > 0 &&
            registro.telefono.length > 0 &&
            registro.edad.length > 0 &&
            registro.paisOrigen.length > 0 
          );
        });

        if(todosTienenDatos)
        {
            let reserva = localStorage.getItem("valores");
            let id_disponibilidad = localStorage.getItem("id_disponibilidad");
            let dataReserva = JSON.parse(reserva+"");
            let nacionalidad = localStorage.getItem("nationalilty")
            const todosTienenDatos = dataReserva?.elementos.every((registro: Elemento) => {
              return (
                registro.tipoDocumento.length > 0 &&
                registro.documento.length > 0 &&
                registro.nombre.length > 0 &&
                registro.correo.length > 0 &&
                registro.telefono.length > 0 &&
                registro.indicativo.length > 0 &&
                registro.edad.length > 0 &&
                registro.paisOrigen.length > 0 
              );
            });
            
            try
            {
             if(todosTienenDatos)
             {
                    setSpinner(true);
                    let codigos:any = [];
                    await Promise.all(
                      dataReserva?.elementos.map(async (el: Elemento) => {
                        let data: Reserve = {
                          payform_id: "3a0b3585-c963-f191-62e9-969012a56415",
                          tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "00000000-0000-0000-0000-000000000000",
                          date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                          document_guest: el?.documento,
                          type_document_guest: el?.tipoDocumento,
                          name_guest: el?.nombre,
                          email_guest: el?.correo,
                          phone_guest: `${el?.indicativo}${el?.telefono}`,
                          age_guest: parseInt(el?.edad),
                          remark: el?.observaciones,
                          gross_amount: (tour_package_json?.price),
                          tax_amount: 0,
                          net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          status: 0,
                          country: el?.paisOrigen,
                          approved: "", 
                          status_transaction: "0",
                          channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                          channel_name: ""
                        };
                        console.log("datass", data);
                        const fecha = hora_cambio == "0001-01-01T00:00:00" ? new Date(tour_package_json?.date_schedul) : new Date(hora_cambio);
    
                        const hora = fecha.getHours().toString().padStart(2, '0');
                        const minutos = fecha.getMinutes().toString().padStart(2, '0');
                        const horaFormateada = `${hora}:${minutos}`;
  
                        let dataCorreo: Correo = {
                          asunto: "Approved",
                          nombre: el?.nombre,
                          email: el?.correo,
                          telefono: `${el?.indicativo}${el?.telefono}`,
                          documento: el?.documento,
                          nombrePaquete: tour_package_json?.tour_package,
                          descripcion: tour_descripcion,
                          punto_encuentro: tour_package_json?.meeting_point,
                          duracion: tour_package_json?.duration,
                          precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          recomendaciones: tour_package_json?.recommendations,
                          politicas_cancelacion: tour_package_json?.policies_cancellation || "Cancellation Policies:",
                          fecha: fecha_sel,
                          hora: horaFormateada,
                          cantidad: (localStorage.getItem("guest_number")+"")
                          // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                        }
                        console.log("correo", dataCorreo);
                        sendEmail(dataCorreo);
                        let correoCapture = {...dataCorreo, email: "reserva@capturecolombiatours.com"}
                        sendEmail(correoCapture);
                        if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                        {
                          let correoCaptureAlternate = {...dataCorreo, email: tour_parse.tour.alternate_email}
                          sendEmail(correoCaptureAlternate);
                        }
                        return reserveDetails(data).then((res) => {
                          codigos.push(res);
                        });
                      })
                    );
                    let nombresReserva = dataReserva?.elementos.map((el: Elemento) => el.nombre);
                    localStorage.setItem("nombresReserva", nombresReserva.toString());
                    localStorage.setItem("codigos_reserva", JSON.stringify(codigos));
                    codigos.map((item: string) => {
                      approvedReserve(item, "Aprobado")
                    })
                    let dataVacia = {
                      guestLabel: [],
                      elementos: []
                    };
                    localStorage.setItem("Reserva_aprobada", "Approved");
                    const dataJSON = JSON.stringify(dataVacia);
                    localStorage.setItem("valores", dataJSON);
                    localStorage.removeItem("theme");
                    localStorage.removeItem("cupos_disponibles");
                    localStorage.removeItem("tour_title");
                    localStorage.removeItem("cantidad_paquetes_fecha");
                    localStorage.removeItem("lastPath");
                    localStorage.removeItem("tour_description");
                    localStorage.removeItem("id_disponibilidad");
                    localStorage.removeItem("urlQuery");
                    navigate('/pay-done');
                    setSpinner(false);
                    Swal.fire({
                      title: 'Reserved',
                      text: 'Your reservation has been approved',
                      icon: 'success',
                      confirmButtonText: 'Accept'
                    })              
             }
             else{
              setSpinner(false);
              Swal.fire({
                title: 'Erro',
                text: 'Please, fill all the information required',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
             }
            }
            catch(err)
            {
              setSpinner(false);
              Swal.fire({
                title: 'Error',
                text: 'Error creating reservation',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
            }
        }
        else
        {
          Swal.fire({
            title: 'Error',
            text: 'Please, fill all the information required',
            icon: 'error',
            confirmButtonText: 'Accept'
          })
        }
      }}
      >
        <span style={{fontSize: "30px"}}>Cash</span>
      </ButtonPrimary>}
          </div>
      </div>
      </Tab.Group>
     
    </div>
  );
};


  const RenderMain = () => {
    const [valoresPay, setValoresPay] = useState<payCard>({
      tipoDocumento: "",
      documento: '',
      nombre: '',
      correo: '',
      telefono: '',
      indicativo: "+57",
      numeroCard: "",
      cvc: ""
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [terminos, setTerminos] = useState<boolean>(false);
  const [indicativo, setIndicativo] = useState<any>("+57");
  const [mostrarTerminos, setMostrarTerminos] = useState<boolean>(false);
  const filterContext = useContext(FilterContext);  

  let hora_cambio =  localStorage.getItem("hora-cambio")+"";
  let cantidad_personas_cambio =  localStorage.getItem("cantidad-personas-cambio")+"";
 
  const handlePopstate = () => {
    filterContext.setPagar(false)
  };
  window.onpopstate  = handlePopstate;

    let tour_descripcion = localStorage.getItem("tour_description")+"";
    let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";

    if(fecha_sel == null)
    {
  
      const year = new Date().getFullYear();
      const month = String(new Date().getMonth() + 1).padStart(2, '0');
      const day = String(new Date().getDate()).padStart(2, '0');
    
      fecha_sel = year+"-"+month+"-"+day;
    }
  
    const [year, month, day] = fecha_sel.split('-');
    let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  

    let valorStr = localStorage.getItem("guest_number")+"";

    let guest_number = parseInt(valorStr);


    let guest_label  : string[] = [];
    for(let ii = 0 ; ii <  guest_number ; ii++)
    {
      guest_label.push("Guest # "+(ii+1));
    };

    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+""); 

    let tour =  localStorage.getItem(tour_package_json.tour_id)
    let tour_parse  = JSON.parse(tour+""); 

    const reserveDetails = async (datos: Reserve) => {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: 'https://capturecolombia.azurewebsites.net/api/app/base/set-reserva-detail',
        data:datos,
        headers: {}
      };

      try {
        const response = await axios.request(config);
        let id_codigo = JSON.stringify(response.data);
        return id_codigo;

        console.log("response", response);

      } catch (error) {
        console.log("error: ",error);
      }

    };

    const handleChangePayCreditCard = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setValoresPay((prevValores) => {
        return { ...prevValores, [name]: value};
      });
    };

    const validarCorreo = (correo: string) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(correo);
    };

    const handleTerms = (event: ChangeEvent<HTMLInputElement>) => {
      setTerminos(event.target.checked);
    };

    return (
      <div style={{border: "1px solid white", borderRadius: "10px", padding: "20px", position: "relative"}}>
              <div className="triangulosCheck" >
              <BsFillTriangleFill className="trianguloUnoCheck"/> 
              <BsFillTriangleFill className="trianguloCheck"/>
              <BsTriangle className="trianguloCheck" style={{color: "#930707"}}/>
          </div> 

        <div style={{width: "100%", textAlign: "center"}}>        
          <h2 className="text-3xl lg:text-4xl font-semibold">
          Personal details and payment
          </h2>
        </div>

        {filterContext.pagar === false && <div>
          <div className="mt-6">
             <MiComponente guestLabel={guest_label}/>
          </div>
          
        </div>}

        {filterContext.pagar &&
                <div>      
                <div className="mt-6">
                  <Tab.Group>
                    <Tab.List className="" style={{marginBottom:"20px", width: "100%", textAlign: "center"}}>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button
                            className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                              selected
                                ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                                : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                            }`}
                          >
                            Pay with credit Card
                          </button>
                        )}
                      </Tab>
                      
                    </Tab.List>
      
                    <Tab.Panels>
                    {/* <Tab.Panel className="space-y-5"> */}
                        {/* <div className="space-y-1">
                          <Label> User Wompi </Label>
                          <Input type="email" defaultValue="" />
                        </div>
                        <div className="space-y-1">
                          <Label>Password Wompi </Label>
                          <Input type="password" defaultValue="" />
                        </div> */}
                   
                      {/* </Tab.Panel> */}
                     
                      <div className="formFlex" style={{marginTop: "20px"}}>
                      <div className="formFlexItems" >
                        <div className="space-y-1" style={{marginBottom: "10px"}}>
                          <label>Name on card</label>
                          <input
                            type="text"
                            name={"nombre"}
                            value={valoresPay.nombre}
                            onChange={(event) => handleChangePayCreditCard(event)}
                          />
                        </div>
                        <div className="space-y-1" style={{marginBottom: "10px"}}>
                          <label>Email</label>
                          <input
                            type="text"
                            name={"correo"}
                            value={valoresPay.correo}
                            onChange={(event) => handleChangePayCreditCard(event)}
                          />
                        </div>
                        <div className="space-y-1" style={{marginBottom: "10px"}}>
                          <label>Phone</label>
                          <div style={{display: "flex", justifyContent: "space-between"}}>
                          <div style={{width: "45%"}}>
                          <Select 
                            value={{value: indicativo, label: indicativo}} 
                            styles={customStyles} 
                            options={indicativos} 
                            onChange={(selectedOption, actionMeta) => {
                                setIndicativo(selectedOption && selectedOption.value)
                              }
                            } />
                          </div>
                          <input
                            style={{width: "50%"}}
                            type="number"
                            name={"telefono"}
                            value={valoresPay.telefono}
                            onChange={(event) => handleChangePayCreditCard(event)}
                          />
                          </div>
                        </div>
                        <div className="space-y-1" style={{marginBottom: "10px"}}>
                          <label>Type of document</label>
                          <select
                            name={`tipoDocumento`}
                            value={valoresPay.tipoDocumento}
                            onChange={(event) => handleChangePayCreditCard(event)}
                          >
                            <option value="">Choose document type</option>
                            <option value="ID">ID</option>
                            <option value="Passport">Passport</option>
                            <option value="DNI">DNI</option>
                            <option value="CC">CC</option>
                            <option value="NIT">NIT</option>
                          </select>
                        </div>
                      </div>

                      <div className="formFlexItems">
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Document Number</label>
                        <input
                          type="text"
                          name={"documento"}
                          value={valoresPay.documento}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        />
                      </div>
                      <div className="space-y-1" style={{marginBottom: "10px"}}>
                        <label>Card Number</label>
                        <input
                          type="number"
                          name={"numeroCard"}
                          value={valoresPay.numeroCard}
                          onChange={(event) => handleChangePayCreditCard(event)}
                        />
                      </div>
                        <div className="flex space-x-20">
                          <div className="space-y-1 datePickerPay" style={{marginBottom: "10px"}}>
                            <label>Expiration Date</label>
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date: Date | null) => setSelectedDate(date)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          </div>
                          <div className="space-y-1" style={{marginBottom: "10px"}}>
                            <label>CVC</label>
                            <input
                              type="text"
                              name={"cvc"}
                              value={valoresPay.cvc}
                              onChange={(event) => handleChangePayCreditCard(event)}
                            />
                          </div>
                        </div>
                        <div className="space-y-1" style={{marginTop: "30px", color: "#2684ff"}}>                            
                            <input
                              type="checkbox"
                              checked={terminos}
                              onChange={handleTerms}
                              style={{width: "25px", height: "25px", marginRight: "20px"}}
                            />
                            <label
                            onClick={() => {
                              filterContext.setMostrarTerminos(true);
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            style={{cursor: "pointer", fontSize: "18px"}}
                            >Terms and Conditions</label>
                        </div>
                      </div>
                      </div>
      
                    </Tab.Panels>
                  </Tab.Group>
                  <div 
                  className="pt-8"
                  style={{display: "flex", justifyContent: "center"}}
                  >
                    <ButtonPrimary 
                    onClick={async () => {
                      let reserva = localStorage.getItem("valores");
                      let id_disponibilidad = localStorage.getItem("id_disponibilidad");
                      let dataReserva = JSON.parse(reserva+"");
                      let nacionalidad = localStorage.getItem("nationalilty")
                      const todosTienenDatos = dataReserva?.elementos.every((registro: Elemento) => {
                        // Verificar cada campo del formulario
                        return (
                          registro.tipoDocumento.length > 0 &&
                          registro.documento.length > 0 &&
                          registro.nombre.length > 0 &&
                          registro.correo.length > 0 &&
                          registro.telefono.length > 0 &&
                          registro.indicativo.length > 0 &&
                          registro.edad.length > 0 &&
                          registro.paisOrigen.length > 0 
                        );
                      });
                      
                      try
                      {
                       if(todosTienenDatos && 
                        dataReserva?.elementos.length > 0 &&
                        valoresPay.correo.length > 0 &&
                        valoresPay.cvc.length > 0 &&
                        valoresPay.documento.length > 0 &&
                        valoresPay.nombre.length > 0 &&
                        valoresPay.numeroCard.length > 0 &&
                        valoresPay.indicativo.length > 0 &&
                        valoresPay.telefono.length > 0 &&
                        valoresPay.tipoDocumento.length > 0                  
                        )
                       {
                        if(validarCorreo(valoresPay.correo))
                        {
                            if(terminos)
                            {
                              setSpinner(true);
                              let codigos:any = [];
                              await Promise.all(
                                dataReserva?.elementos.map(async (el: Elemento) => {
                                  let data: Reserve = {
                                    payform_id: "3a0b48b4-ab1c-c7fc-45c1-d88a8a08a784",
                                    tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "",
                                    date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                                    document_guest: el?.documento,
                                    type_document_guest: el?.tipoDocumento,
                                    name_guest: el?.nombre,
                                    email_guest: el?.correo,
                                    phone_guest: `${el?.indicativo}${el?.telefono}`,
                                    age_guest: parseInt(el?.edad),
                                    remark: el?.observaciones,
                                    gross_amount: (tour_package_json?.price),
                                    tax_amount: 0,
                                    net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                                    status: 0,
                                    country: el?.paisOrigen,
                                    approved: "",
                                    status_transaction: "0",
                                    channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                                    channel_name: ""
                                  };
            
                                  const fecha = hora_cambio == "0001-01-01T00:00:00" ? new Date(tour_package_json?.date_schedul) : new Date(hora_cambio);
              
                                  const hora = fecha.getHours().toString().padStart(2, '0');
                                  const minutos = fecha.getMinutes().toString().padStart(2, '0');
                                  const horaFormateada = `${hora}:${minutos}`;
            
                                  let dataCorreo: Correo = {
                                    asunto: "Approved",
                                    nombre: el?.nombre,
                                    email: el?.correo,
                                    telefono: `${el?.indicativo}${el?.telefono}`,
                                    documento: el?.documento,
                                    nombrePaquete: tour_package_json?.tour_package,
                                    descripcion: tour_descripcion,
                                    punto_encuentro: tour_package_json?.meeting_point,
                                    duracion: tour_package_json?.duration,
                                    precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                                    recomendaciones: tour_package_json?.recommendations,
                                    politicas_cancelacion: tour_package_json?.policies_cancellation,
                                    fecha: fecha_sel,
                                    hora: horaFormateada,
                                    cantidad: (localStorage.getItem("guest_number")+"")
                                    // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                                  }
                                  localStorage.setItem("data_correo", JSON.stringify(dataCorreo));
                                  
                                  return reserveDetails(data).then((res) => {
                                    codigos.push(res);
                                  });
                                })
                              );
                              let nombresReserva = dataReserva?.elementos.map((el: Elemento) => el.nombre);
                              localStorage.setItem("nombresReserva", nombresReserva.toString());
                              localStorage.setItem("codigos_reserva", JSON.stringify(codigos))
                              let dataVacia = {
                                guestLabel: [],
                                elementos: []
                              }
                              let reserveId = uuidv4();
                              let cantidadWompi = (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - ((parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100) * parseInt(localStorage.getItem("guest_number")+""))
                              let dataWompi: WompiData = {
                                tarjeta: {
                                number: valoresPay.numeroCard,
                                exp_month: selectedDate ? (selectedDate.getMonth() + 1).toString().length === 1 ?
                                  `0${(selectedDate.getMonth() + 1).toString()}` :
                                  (selectedDate.getMonth() + 1).toString()
                                : "",
                                exp_year: selectedDate ? selectedDate.getFullYear().toString().slice(2) : "",
                                cvc: valoresPay.cvc,
                                card_holder: valoresPay.nombre
                                },
                                customer_email: valoresPay.correo,
                                request_transaction: {
                                  acceptance_token: "",
                                  amount_in_cents: cantidadWompi * 100,
                                  currency: "COP",
                                  customer_email: valoresPay.correo,
                                  payment_method: {
                                    type: "CARD",
                                    token: "",
                                    installments: 1
                                  },
                                  payment_source_id: 0,
                                  redirect_url: "https://capturecolombia.azurewebsites.net/paydone",
                                  reference: reserveId,
                                  customer_data: {
                                    phone_number: `${valoresPay.indicativo}${valoresPay.telefono}`,
                                    full_name: valoresPay.nombre,
                                    legal_id: valoresPay.documento,
                                    legal_id_type: valoresPay.tipoDocumento
                                  },
                                  shipping_address: {
                                  address_line_1: "Calle 50B # 2F2 - 34",
                                    address_line_2: "Apartamento 502, Torre I",
                                    country: "CO",
                                    region: "Atlantico",
                                    city: "Soledad",
                                    name: valoresPay.nombre,
                                    phone_number: `${valoresPay.indicativo}${valoresPay.telefono}`,
                                    postal_code: "111111"
                                  }
                                },
                                reserver_detail_id: reserveId
                              }
          
                              try {
                                const res: any = await sendWompi(dataWompi);
                                console.log("res wonpi", res)
                                if (res.data.status === "PENDING") {
                                  setTimeout(async () => {
                                    const validate: any = await validateWompi(res.data.transaccion.data.id);
                                  console.log("res validate", validate)
                              
                                    if (validate.data === "APPROVED") {
                                      codigos.map((item: string) => {
                                        approvedReserve(item, "Aprobado");
                                      });
                                      let datacorreo =  localStorage.getItem("data_correo");
                                      let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                      sendEmail(dataCorreoEnviar);
                                      let correoCapture = {...dataCorreoEnviar, email: "reserva@capturecolombiatours.com"}
                                      sendEmail(correoCapture);
                                      if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                      localStorage.setItem("Reserva_aprobada", "APPROVED");
                                      setSpinner(false);
                                      const dataJSON = JSON.stringify(dataVacia);
                                      localStorage.setItem("valores", dataJSON);
                                      localStorage.removeItem("theme");
                                      localStorage.removeItem("cupos_disponibles");
                                      localStorage.removeItem("tour_title");
                                      localStorage.removeItem("cantidad_paquetes_fecha");
                                      localStorage.removeItem("lastPath");
                                      localStorage.removeItem("tour_description");
                                      localStorage.removeItem("id_disponibilidad");
                                      localStorage.removeItem("urlQuery");
                                      localStorage.removeItem("data_correo");
                                      localStorage.removeItem("canal");
                                      navigate('/pay-done');
                                      Swal.fire({
                                        title: 'Reserved',
                                        text: 'Your reservation has been approved',
                                        icon: 'success',
                                        confirmButtonText: 'Accept'
                                      });
                                    } else {
                                      codigos.map((item: string) => {
                                        approvedReserve(item, "Rechazado");
                                      });
                                      let datacorreo = localStorage.getItem("data_correo");
                                      let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                    let correo_rejected = {...dataCorreoEnviar, asunto: "Rejected", email: "reserva@capturecolombiatours.com"}
                                      sendEmail(correo_rejected);
                                      if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, asunto: "Rejected", email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                      localStorage.setItem("Reserva_aprobada", "REJECTED");
                                      setSpinner(false);
                                      navigate('/pay-done');
                                      Swal.fire({
                                        title: 'Rejected',
                                        text: 'Your reservation has been rejected, contact 573053409944',
                                        icon: 'error',
                                        confirmButtonText: 'Accept'
                                      });
                                    }
                                    
                                  }, 10000);
                                } else {
                                  codigos.map((item: string) => {
                                    approvedReserve(item, "Rechazado");
                                  });
                                  let datacorreo = localStorage.getItem("data_correo");
                                  let dataCorreoEnviar = datacorreo && JSON.parse(datacorreo);
                                  let correo_rejected = {...dataCorreoEnviar, asunto: "Rejected", email: "reserva@capturecolombiatours.com"}
                                  sendEmail(correo_rejected);
                                  if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                                      {
                                        let correoCaptureAlternate = {...dataCorreoEnviar, asunto: "Rejected", email: tour_parse.tour.alternate_email}
                                        sendEmail(correoCaptureAlternate);
                                      }
                                  localStorage.setItem("Reserva_aprobada", "REJECTED");
                                  setSpinner(false);
                                  const dataJSON = JSON.stringify(dataVacia);
                                  localStorage.setItem("valores", dataJSON);
                                  localStorage.removeItem("theme");
                                  localStorage.removeItem("cupos_disponibles");
                                  localStorage.removeItem("tour_title");
                                  localStorage.removeItem("cantidad_paquetes_fecha");
                                  localStorage.removeItem("lastPath");
                                  localStorage.removeItem("tour_description");
                                  localStorage.removeItem("id_disponibilidad");
                                  localStorage.removeItem("urlQuery");
                                  localStorage.removeItem("canal");
                                  navigate('/pay-done');
                                  Swal.fire({
                                    title: 'Rejected',
                                    text: 'Your reservation has been rejected, contact 573053409944',
                                    icon: 'error',
                                    confirmButtonText: 'Accept'
                                  });
                                }                        
                            
                              } catch (error) {
                                console.log(error);
                              }
                              

                            }
                            else
                            {
                              Swal.fire({
                                title: 'Terms and Conditions',
                                text: 'You must accept terms and conditions',
                                icon: 'error',
                                confirmButtonText: 'Accept'
                              })
                            }
                        }
                        else
                        {
                          Swal.fire({
                            title: 'Error',
                            text: 'Invalid email',
                            icon: 'error',
                            confirmButtonText: 'Accept'
                          })
                        }                  
                       }
                       else{
                        Swal.fire({
                          title: 'Error',
                          text: 'Please, fill all the information required',
                          icon: 'error',
                          confirmButtonText: 'Accept'
                        })
                       }
                      }
                      catch(err)
                      {
                        Swal.fire({
                          title: 'Error',
                          text: 'Error creating reservation',
                          icon: 'error',
                          confirmButtonText: 'Accept'
                        })
                      }
                    }}
                    ><span style={{fontSize: "30px"}}>Confirm and pay</span></ButtonPrimary>
                  </div>
                </div>
      
              </div>
        }
          <Terminos />
      </div>
    );
  };

  return (
    <div>
      {spinner && <Spinner texto="Processing payment" />}
      <div style={{display: "flex", justifyContent: "center", margin: "-60px 0"}}>
            <img className="LogoCheckout Logowidth" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2FLogo-Capture.png?alt=media&token=eca452ce-4af9-45ac-a8d4-d916f5d436c6"/>
      </div>
      <div className="almostReady">
          <p>We are<span style={{color: "#fff741"}}> almost</span> ready!</p>
      </div>
    {/* <div className={`nc-CheckOutPagePageMain ${className}`} style={{background: "#232323"}}> */}
      <main className="mt-11 mb-24 lg:mb-32 flex flex-col-reverse" style={{height: "auto"}}>
        <div className="w-full">{RenderMain()}</div>
        <div className="w-full">{renderSidebar()}</div>
      </main>
    {/* </div> */}
    </div>
  );
};

export default CheckOutPagePageMain;