import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import FilterContext from "helpers/filter/FilterContext";
import DetailPagetLayout from 'containers/ListingDetailPage/Layout';
import CheckOutPagePageMain from './CheckOutPage';
import { isMobile } from 'react-device-detect';
import CheckoutMovil from './CheckoutMovil';
import axios, { AxiosRequestConfig } from 'axios';
import { Tab } from "@headlessui/react";
import Select, { ActionMeta, SingleValue } from 'react-select';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getCountries, sendWompi, sendEmail, approvedReserve, validateWompi } from "api/backendApi";
import { countryCodes } from "./indicativos";
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillTriangleFill, BsTriangle } from 'react-icons/bs';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white'
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #374151',
    borderRadius: 20,
    color: 'white',
    '& > div': {
      color: 'white',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2684ff' : '#232323',
    color: 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2684ff' : '#2684ff',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: '#232323',
  }),
};

const lista_paises = [
  {
      "value": "Afghanistan",
      "label": "Afghanistan"
  },
  {
      "value": "Albania",
      "label": "Albania"
  },
  {
      "value": "Algeria",
      "label": "Algeria"
  },
  {
      "value": "American Samoa",
      "label": "American Samoa"
  },
  {
      "value": "Andorra",
      "label": "Andorra"
  },
  {
      "value": "Angola",
      "label": "Angola"
  },
  {
      "value": "Anguilla",
      "label": "Anguilla"
  },
  {
      "value": "Antarctica",
      "label": "Antarctica"
  },
  {
      "value": "Antigua and Barbuda",
      "label": "Antigua and Barbuda"
  },
  {
      "value": "Argentina",
      "label": "Argentina"
  },
  {
      "value": "Armenia",
      "label": "Armenia"
  },
  {
      "value": "Aruba",
      "label": "Aruba"
  },
  {
      "value": "Australia",
      "label": "Australia"
  },
  {
      "value": "Austria",
      "label": "Austria"
  },
  {
      "value": "Azerbaijan",
      "label": "Azerbaijan"
  },
  {
      "value": "Bahamas",
      "label": "Bahamas"
  },
  {
      "value": "Bahrain",
      "label": "Bahrain"
  },
  {
      "value": "Bangladesh",
      "label": "Bangladesh"
  },
  {
      "value": "Barbados",
      "label": "Barbados"
  },
  {
      "value": "Belarus",
      "label": "Belarus"
  },
  {
      "value": "Belgium",
      "label": "Belgium"
  },
  {
      "value": "Belize",
      "label": "Belize"
  },
  {
      "value": "Benin",
      "label": "Benin"
  },
  {
      "value": "Bermuda",
      "label": "Bermuda"
  },
  {
      "value": "Bhutan",
      "label": "Bhutan"
  },
  {
      "value": "Bolivia",
      "label": "Bolivia"
  },
  {
      "value": "Bosnia and Herzegovina",
      "label": "Bosnia and Herzegovina"
  },
  {
      "value": "Botswana",
      "label": "Botswana"
  },
  {
      "value": "Bouvet Island",
      "label": "Bouvet Island"
  },
  {
      "value": "Brazil",
      "label": "Brazil"
  },
  {
      "value": "British Indian Ocean Territory",
      "label": "British Indian Ocean Territory"
  },
  {
      "value": "British Virgin Islands",
      "label": "British Virgin Islands"
  },
  {
      "value": "Brunei",
      "label": "Brunei"
  },
  {
      "value": "Bulgaria",
      "label": "Bulgaria"
  },
  {
      "value": "Burkina Faso",
      "label": "Burkina Faso"
  },
  {
      "value": "Burundi",
      "label": "Burundi"
  },
  {
      "value": "Cambodia",
      "label": "Cambodia"
  },
  {
      "value": "Cameroon",
      "label": "Cameroon"
  },
  {
      "value": "Canada",
      "label": "Canada"
  },
  {
      "value": "Cape Verde",
      "label": "Cape Verde"
  },
  {
      "value": "Caribbean Netherlands",
      "label": "Caribbean Netherlands"
  },
  {
      "value": "Cayman Islands",
      "label": "Cayman Islands"
  },
  {
      "value": "Central African Republic",
      "label": "Central African Republic"
  },
  {
      "value": "Chad",
      "label": "Chad"
  },
  {
      "value": "Chile",
      "label": "Chile"
  },
  {
      "value": "China",
      "label": "China"
  },
  {
      "value": "Christmas Island",
      "label": "Christmas Island"
  },
  {
      "value": "Cocos (Keeling) Islands",
      "label": "Cocos (Keeling) Islands"
  },
  {
      "value": "Colombia",
      "label": "Colombia"
  },
  {
      "value": "Comoros",
      "label": "Comoros"
  },
  {
      "value": "Cook Islands",
      "label": "Cook Islands"
  },
  {
      "value": "Costa Rica",
      "label": "Costa Rica"
  },
  {
      "value": "Croatia",
      "label": "Croatia"
  },
  {
      "value": "Cuba",
      "label": "Cuba"
  },
  {
      "value": "Curaçao",
      "label": "Curaçao"
  },
  {
      "value": "Cyprus",
      "label": "Cyprus"
  },
  {
      "value": "Czechia",
      "label": "Czechia"
  },
  {
      "value": "DR Congo",
      "label": "DR Congo"
  },
  {
      "value": "Denmark",
      "label": "Denmark"
  },
  {
      "value": "Djibouti",
      "label": "Djibouti"
  },
  {
      "value": "Dominica",
      "label": "Dominica"
  },
  {
      "value": "Dominican Republic",
      "label": "Dominican Republic"
  },
  {
      "value": "Ecuador",
      "label": "Ecuador"
  },
  {
      "value": "Egypt",
      "label": "Egypt"
  },
  {
      "value": "El Salvador",
      "label": "El Salvador"
  },
  {
      "value": "Equatorial Guinea",
      "label": "Equatorial Guinea"
  },
  {
      "value": "Eritrea",
      "label": "Eritrea"
  },
  {
      "value": "Estonia",
      "label": "Estonia"
  },
  {
      "value": "Eswatini",
      "label": "Eswatini"
  },
  {
      "value": "Ethiopia",
      "label": "Ethiopia"
  },
  {
      "value": "Falkland Islands",
      "label": "Falkland Islands"
  },
  {
      "value": "Faroe Islands",
      "label": "Faroe Islands"
  },
  {
      "value": "Fiji",
      "label": "Fiji"
  },
  {
      "value": "Finland",
      "label": "Finland"
  },
  {
      "value": "France",
      "label": "France"
  },
  {
      "value": "French Guiana",
      "label": "French Guiana"
  },
  {
      "value": "French Polynesia",
      "label": "French Polynesia"
  },
  {
      "value": "French Southern and Antarctic Lands",
      "label": "French Southern and Antarctic Lands"
  },
  {
      "value": "Gabon",
      "label": "Gabon"
  },
  {
      "value": "Gambia",
      "label": "Gambia"
  },
  {
      "value": "Georgia",
      "label": "Georgia"
  },
  {
      "value": "Germany",
      "label": "Germany"
  },
  {
      "value": "Ghana",
      "label": "Ghana"
  },
  {
      "value": "Gibraltar",
      "label": "Gibraltar"
  },
  {
      "value": "Greece",
      "label": "Greece"
  },
  {
      "value": "Greenland",
      "label": "Greenland"
  },
  {
      "value": "Grenada",
      "label": "Grenada"
  },
  {
      "value": "Guadeloupe",
      "label": "Guadeloupe"
  },
  {
      "value": "Guam",
      "label": "Guam"
  },
  {
      "value": "Guatemala",
      "label": "Guatemala"
  },
  {
      "value": "Guernsey",
      "label": "Guernsey"
  },
  {
      "value": "Guinea",
      "label": "Guinea"
  },
  {
      "value": "Guinea-Bissau",
      "label": "Guinea-Bissau"
  },
  {
      "value": "Guyana",
      "label": "Guyana"
  },
  {
      "value": "Haiti",
      "label": "Haiti"
  },
  {
      "value": "Heard Island and McDonald Islands",
      "label": "Heard Island and McDonald Islands"
  },
  {
      "value": "Honduras",
      "label": "Honduras"
  },
  {
      "value": "Hong Kong",
      "label": "Hong Kong"
  },
  {
      "value": "Hungary",
      "label": "Hungary"
  },
  {
      "value": "Iceland",
      "label": "Iceland"
  },
  {
      "value": "India",
      "label": "India"
  },
  {
      "value": "Indonesia",
      "label": "Indonesia"
  },
  {
      "value": "Iran",
      "label": "Iran"
  },
  {
      "value": "Iraq",
      "label": "Iraq"
  },
  {
      "value": "Ireland",
      "label": "Ireland"
  },
  {
      "value": "Isle of Man",
      "label": "Isle of Man"
  },
  {
      "value": "Israel",
      "label": "Israel"
  },
  {
      "value": "Italy",
      "label": "Italy"
  },
  {
      "value": "Ivory Coast",
      "label": "Ivory Coast"
  },
  {
      "value": "Jamaica",
      "label": "Jamaica"
  },
  {
      "value": "Japan",
      "label": "Japan"
  },
  {
      "value": "Jersey",
      "label": "Jersey"
  },
  {
      "value": "Jordan",
      "label": "Jordan"
  },
  {
      "value": "Kazakhstan",
      "label": "Kazakhstan"
  },
  {
      "value": "Kenya",
      "label": "Kenya"
  },
  {
      "value": "Kiribati",
      "label": "Kiribati"
  },
  {
      "value": "Kosovo",
      "label": "Kosovo"
  },
  {
      "value": "Kuwait",
      "label": "Kuwait"
  },
  {
      "value": "Kyrgyzstan",
      "label": "Kyrgyzstan"
  },
  {
      "value": "Laos",
      "label": "Laos"
  },
  {
      "value": "Latvia",
      "label": "Latvia"
  },
  {
      "value": "Lebanon",
      "label": "Lebanon"
  },
  {
      "value": "Lesotho",
      "label": "Lesotho"
  },
  {
      "value": "Liberia",
      "label": "Liberia"
  },
  {
      "value": "Libya",
      "label": "Libya"
  },
  {
      "value": "Liechtenstein",
      "label": "Liechtenstein"
  },
  {
      "value": "Lithuania",
      "label": "Lithuania"
  },
  {
      "value": "Luxembourg",
      "label": "Luxembourg"
  },
  {
      "value": "Macau",
      "label": "Macau"
  },
  {
      "value": "Madagascar",
      "label": "Madagascar"
  },
  {
      "value": "Malawi",
      "label": "Malawi"
  },
  {
      "value": "Malaysia",
      "label": "Malaysia"
  },
  {
      "value": "Maldives",
      "label": "Maldives"
  },
  {
      "value": "Mali",
      "label": "Mali"
  },
  {
      "value": "Malta",
      "label": "Malta"
  },
  {
      "value": "Marshall Islands",
      "label": "Marshall Islands"
  },
  {
      "value": "Martinique",
      "label": "Martinique"
  },
  {
      "value": "Mauritania",
      "label": "Mauritania"
  },
  {
      "value": "Mauritius",
      "label": "Mauritius"
  },
  {
      "value": "Mayotte",
      "label": "Mayotte"
  },
  {
      "value": "Mexico",
      "label": "Mexico"
  },
  {
      "value": "Micronesia",
      "label": "Micronesia"
  },
  {
      "value": "Moldova",
      "label": "Moldova"
  },
  {
      "value": "Monaco",
      "label": "Monaco"
  },
  {
      "value": "Mongolia",
      "label": "Mongolia"
  },
  {
      "value": "Montenegro",
      "label": "Montenegro"
  },
  {
      "value": "Montserrat",
      "label": "Montserrat"
  },
  {
      "value": "Morocco",
      "label": "Morocco"
  },
  {
      "value": "Mozambique",
      "label": "Mozambique"
  },
  {
      "value": "Myanmar",
      "label": "Myanmar"
  },
  {
      "value": "Namibia",
      "label": "Namibia"
  },
  {
      "value": "Nauru",
      "label": "Nauru"
  },
  {
      "value": "Nepal",
      "label": "Nepal"
  },
  {
      "value": "Netherlands",
      "label": "Netherlands"
  },
  {
      "value": "New Caledonia",
      "label": "New Caledonia"
  },
  {
      "value": "New Zealand",
      "label": "New Zealand"
  },
  {
      "value": "Nicaragua",
      "label": "Nicaragua"
  },
  {
      "value": "Niger",
      "label": "Niger"
  },
  {
      "value": "Nigeria",
      "label": "Nigeria"
  },
  {
      "value": "Niue",
      "label": "Niue"
  },
  {
      "value": "Norfolk Island",
      "label": "Norfolk Island"
  },
  {
      "value": "North Korea",
      "label": "North Korea"
  },
  {
      "value": "North Macedonia",
      "label": "North Macedonia"
  },
  {
      "value": "Northern Mariana Islands",
      "label": "Northern Mariana Islands"
  },
  {
      "value": "Norway",
      "label": "Norway"
  },
  {
      "value": "Oman",
      "label": "Oman"
  },
  {
      "value": "Pakistan",
      "label": "Pakistan"
  },
  {
      "value": "Palau",
      "label": "Palau"
  },
  {
      "value": "Palestine",
      "label": "Palestine"
  },
  {
      "value": "Panama",
      "label": "Panama"
  },
  {
      "value": "Papua New Guinea",
      "label": "Papua New Guinea"
  },
  {
      "value": "Paraguay",
      "label": "Paraguay"
  },
  {
      "value": "Peru",
      "label": "Peru"
  },
  {
      "value": "Philippines",
      "label": "Philippines"
  },
  {
      "value": "Pitcairn Islands",
      "label": "Pitcairn Islands"
  },
  {
      "value": "Poland",
      "label": "Poland"
  },
  {
      "value": "Portugal",
      "label": "Portugal"
  },
  {
      "value": "Puerto Rico",
      "label": "Puerto Rico"
  },
  {
      "value": "Qatar",
      "label": "Qatar"
  },
  {
      "value": "Republic of the Congo",
      "label": "Republic of the Congo"
  },
  {
      "value": "Romania",
      "label": "Romania"
  },
  {
      "value": "Russia",
      "label": "Russia"
  },
  {
      "value": "Rwanda",
      "label": "Rwanda"
  },
  {
      "value": "Réunion",
      "label": "Réunion"
  },
  {
      "value": "Saint Barthélemy",
      "label": "Saint Barthélemy"
  },
  {
      "value": "Saint Helena, Ascension and Tristan da Cunha",
      "label": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
      "value": "Saint Kitts and Nevis",
      "label": "Saint Kitts and Nevis"
  },
  {
      "value": "Saint Lucia",
      "label": "Saint Lucia"
  },
  {
      "value": "Saint Martin",
      "label": "Saint Martin"
  },
  {
      "value": "Saint Pierre and Miquelon",
      "label": "Saint Pierre and Miquelon"
  },
  {
      "value": "Saint Vincent and the Grenadines",
      "label": "Saint Vincent and the Grenadines"
  },
  {
      "value": "Samoa",
      "label": "Samoa"
  },
  {
      "value": "San Marino",
      "label": "San Marino"
  },
  {
      "value": "Saudi Arabia",
      "label": "Saudi Arabia"
  },
  {
      "value": "Senegal",
      "label": "Senegal"
  },
  {
      "value": "Serbia",
      "label": "Serbia"
  },
  {
      "value": "Seychelles",
      "label": "Seychelles"
  },
  {
      "value": "Sierra Leone",
      "label": "Sierra Leone"
  },
  {
      "value": "Singapore",
      "label": "Singapore"
  },
  {
      "value": "Sint Maarten",
      "label": "Sint Maarten"
  },
  {
      "value": "Slovakia",
      "label": "Slovakia"
  },
  {
      "value": "Slovenia",
      "label": "Slovenia"
  },
  {
      "value": "Solomon Islands",
      "label": "Solomon Islands"
  },
  {
      "value": "Somalia",
      "label": "Somalia"
  },
  {
      "value": "South Africa",
      "label": "South Africa"
  },
  {
      "value": "South Georgia",
      "label": "South Georgia"
  },
  {
      "value": "South Korea",
      "label": "South Korea"
  },
  {
      "value": "South Sudan",
      "label": "South Sudan"
  },
  {
      "value": "Spain",
      "label": "Spain"
  },
  {
      "value": "Sri Lanka",
      "label": "Sri Lanka"
  },
  {
      "value": "Sudan",
      "label": "Sudan"
  },
  {
      "value": "Suriname",
      "label": "Suriname"
  },
  {
      "value": "Svalbard and Jan Mayen",
      "label": "Svalbard and Jan Mayen"
  },
  {
      "value": "Sweden",
      "label": "Sweden"
  },
  {
      "value": "Switzerland",
      "label": "Switzerland"
  },
  {
      "value": "Syria",
      "label": "Syria"
  },
  {
      "value": "São Tomé and Príncipe",
      "label": "São Tomé and Príncipe"
  },
  {
      "value": "Taiwan",
      "label": "Taiwan"
  },
  {
      "value": "Tajikistan",
      "label": "Tajikistan"
  },
  {
      "value": "Tanzania",
      "label": "Tanzania"
  },
  {
      "value": "Thailand",
      "label": "Thailand"
  },
  {
      "value": "Timor-Leste",
      "label": "Timor-Leste"
  },
  {
      "value": "Togo",
      "label": "Togo"
  },
  {
      "value": "Tokelau",
      "label": "Tokelau"
  },
  {
      "value": "Tonga",
      "label": "Tonga"
  },
  {
      "value": "Trinidad and Tobago",
      "label": "Trinidad and Tobago"
  },
  {
      "value": "Tunisia",
      "label": "Tunisia"
  },
  {
      "value": "Turkey",
      "label": "Turkey"
  },
  {
      "value": "Turkmenistan",
      "label": "Turkmenistan"
  },
  {
      "value": "Turks and Caicos Islands",
      "label": "Turks and Caicos Islands"
  },
  {
      "value": "Tuvalu",
      "label": "Tuvalu"
  },
  {
      "value": "Uganda",
      "label": "Uganda"
  },
  {
      "value": "Ukraine",
      "label": "Ukraine"
  },
  {
      "value": "United Arab Emirates",
      "label": "United Arab Emirates"
  },
  {
      "value": "United Kingdom",
      "label": "United Kingdom"
  },
  {
      "value": "United States",
      "label": "United States"
  },
  {
      "value": "United States Minor Outlying Islands",
      "label": "United States Minor Outlying Islands"
  },
  {
      "value": "United States Virgin Islands",
      "label": "United States Virgin Islands"
  },
  {
      "value": "Uruguay",
      "label": "Uruguay"
  },
  {
      "value": "Uzbekistan",
      "label": "Uzbekistan"
  },
  {
      "value": "Vanuatu",
      "label": "Vanuatu"
  },
  {
      "value": "Vatican City",
      "label": "Vatican City"
  },
  {
      "value": "Venezuela",
      "label": "Venezuela"
  },
  {
      "value": "Vietnam",
      "label": "Vietnam"
  },
  {
      "value": "Wallis and Futuna",
      "label": "Wallis and Futuna"
  },
  {
      "value": "Western Sahara",
      "label": "Western Sahara"
  },
  {
      "value": "Yemen",
      "label": "Yemen"
  },
  {
      "value": "Zambia",
      "label": "Zambia"
  },
  {
      "value": "Zimbabwe",
      "label": "Zimbabwe"
  },
  {
      "value": "Åland Islands",
      "label": "Åland Islands"
  }
];

const FormPayGuest = () => {
  let valorStr = localStorage.getItem("guest_number")+"";

  let guest_number = parseInt(valorStr);
  
  let guest_label = [];
  for(let ii = 0 ; ii <  guest_number ; ii++)
  {
    guest_label.push("Guest # "+(ii+1));
  };

  const [valores, setValores] = useState({
    guest_label,
    elementos: Array(guest_label.length).fill({
      tipoDocumento: '',
      documento: '',
      nombre: '',
      correo: '',
      telefono: '',
      indicativo: '',
      edad: '',
      observaciones: '',
      paisOrigen: ''
    })
  });
    const [terminos, setTerminos] = useState(false);
    const [paisesSelect, setPaisesSelect] = useState([]);
    const [paises, setPaises] = useState([]);
    const [indicativos, setIndicativos] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const filterContext = useContext(FilterContext);

    useEffect(() => {
      const hasEffectRun = JSON.parse(localStorage.getItem('hasEffectRun'));

      // if (!hasEffectRun) {
      //   localStorage.setItem('hasEffectRun', 'true');
      //   window.location.reload();
      // }
      let dataVacia = {
        guestLabel: [],
        elementos: []
      }
      const dataJSON = JSON.stringify(dataVacia);
      localStorage.setItem("valores", dataJSON)
  
      getCountries().then(res => {
        let options = res.map((el) => {
          return { value: el.name.common, label: el.name.common };
        });
      
        options.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
      
        setPaisesSelect(options);
        setPaises(res);
      });
      let indica = countryCodes.map( (el) => {
        return { value: el.code, label: el.name };
      })
      setIndicativos(indica)
    }, [])

    const navigate = useNavigate();

    let tour_package_str =  localStorage.getItem("paquete_seleccionado")
    let tour_package_json  = JSON.parse(tour_package_str+""); 

    let fecha_sel = localStorage.getItem("fecha_seleccionada")+"";
    let tour_descripcion = localStorage.getItem("tour_description")+"";

    let hora_cambio =  localStorage.getItem("hora-cambio")+"";
    let cantidad_personas_cambio =  localStorage.getItem("cantidad-personas-cambio")+"";

    let tour =  localStorage.getItem(tour_package_json.tour_id)
    let tour_parse  = JSON.parse(tour+""); 

    if(fecha_sel == null)
    {
  
      const year = new Date().getFullYear();
      const month = String(new Date().getMonth() + 1).padStart(2, '0');
      const day = String(new Date().getDate()).padStart(2, '0');
    
      fecha_sel = year+"-"+month+"-"+day;
    }
  
    const [year, month, day] = fecha_sel.split('-');
    let date_selec = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

    const reserveDetails = async (datos) => {
      const config = {
        method: 'post',
        url: 'https://capturecolombia.azurewebsites.net/api/app/base/set-reserva-detail',
        data:datos,
        headers: {}
      };

      try {
        const response = await axios.request(config);
        let id_codigo = JSON.stringify(response.data);
        return id_codigo;

        console.log("response", response);

      } catch (error) {
        console.log("error: ",error);
      }

    };

const handleChange = (index, event) => {
  const { name, value } = event.target;
  setValores((prevValores) => {
    const elementosActualizados = prevValores.elementos.map((elemento, i) => {
      if (i === index) {
        return {
          ...elemento,
          [name.split("_")[0]]: value
        };
      }
      return elemento;
    });
    return { ...prevValores, elementos: elementosActualizados };
  });
  let datos = JSON.stringify(valores);
};

const handleChangeSelect = (selectedOption, actionMeta, name, index) => {
  setValores((prevValores) => {
    const elementosActualizados = prevValores.elementos.map((elemento, i) => {
      if (i === index) {
        return {
          ...elemento,
          [name.split("_")[0]]: selectedOption ? selectedOption.value : null
        };
      }
      return elemento;
    });
    return { ...prevValores, elementos: elementosActualizados };
  });
};


const handleTerms = (event) => {
  setTerminos(event.target.checked);
};

  return (
      <div style={{border: "1px solid white", borderRadius: "10px", padding: "20px", position: "relative"}}>
              {/* <div className="triangulosCheck" >
              <BsFillTriangleFill className="trianguloUnoCheck"/> 
              <BsFillTriangleFill className="trianguloCheck"/>
              <BsTriangle className="trianguloCheck" style={{color: "#930707"}}/>
              </div>  */}

        <div style={{width: "100%", textAlign: "center"}}>        
          <h2 className="text-3xl lg:text-4xl font-semibold">
          Personal details and payment
          </h2>
        </div>

        {filterContext.pagar === false && <div>
          <div className="mt-6">
          <Tab.Group>
              <Tab.List className="" style={{marginBottom:"20px", width: "100%", textAlign: "center"}}>
              
              {valores.guest_label.map((element, index) => (
                                  <Tab 
                                  key={index}
                                  as={Fragment}>
                                    {({ selected }) => (
                                      <button
                                        style={{background: "#fefa9b"}}
                                        className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                                          selected
                                            ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                                            : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                                        }`}
                                      >
                                        {element}
                                      </button>
                                    )}
                                  </Tab>
                ))}

              </Tab.List>
          
      <Tab.Panels>
        {valores.guest_label.map((element, index) => (
      
          <Tab.Panel className="space-y-5 formFlex" key={index}>
           
          <div className="formFlexItems" style={{marginTop: "20px"}}>
            <div className="space-y-1">
                <label>Type of document</label>
                <select
                  name={`tipoDocumento_${index}`}
                  value={valores.elementos[index]?.tipoDocumento || ''}
                  onChange={(event) => handleChange(index, event)}
                >
                  <option value="">Choose document type</option>
                  <option value="ID">ID</option>
                  <option value="Passport">Passport</option>
                  <option value="DNI">DNI</option>
                  <option value="CC">CC</option>
                  <option value="NIT">NIT</option>
                </select>
              </div>
              <div className="space-y-1">
                <label>Document number</label>
                <input
                  type="text"
                  name={`documento_${index}`}
                  value={valores.elementos[index].documento }
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Full name</label>
                <input
                  type="text"
                  name={`nombre_${index}`}
                  value={valores.elementos[index]?.nombre || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Nationality</label>
                <Select 
                name={`paisOrigen_${index}`} 
                value={{value: valores && valores.elementos[index]?.paisOrigen || '', label: valores && valores.elementos[index]?.paisOrigen || ''}} 
                styles={customStyles} 
                options={lista_paises} 
                onChange={(selectedOption, actionMeta) =>
                  handleChangeSelect(selectedOption, actionMeta, `paisOrigen_${index}`, index)
                } />
              </div>
          </div>

          <div className="formFlexItems">
            <div className="space-y-1">
                <label>Email</label>
                <input
                  type="email"
                  name={`correo_${index}`}
                  value={valores.elementos[index]?.correo || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Mobile phone</label>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "40%"}}>
                <Select 
                name={`indicativo_${index}`} 
                value={{value: valores && valores.elementos[index]?.indicativo || '', label: valores && valores.elementos[index]?.indicativo || ''}} 
                styles={customStyles} 
                options={indicativos} 
                onChange={(selectedOption, actionMeta) =>
                  handleChangeSelect(selectedOption, actionMeta, `indicativo_${index}`, index)
                } />
                </div>
                <input
                  style={{width: "50%"}}
                  type="number"
                  name={`telefono_${index}`}
                  value={valores.elementos[index]?.telefono || ''}
                  onChange={(event) => handleChange(index, event)}
                />
                </div>
              </div>
              <div className="space-y-1">
                <label>Age</label>
                <input
                  type="number"
                  name={`edad_${index}`}
                  value={valores.elementos[index]?.edad || ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
              <div className="space-y-1">
                <label>Comments</label>
                <textarea
                  name={`observaciones_${index}`}
                  value={valores.elementos[index]?.observaciones || ''}
                  onChange={(event) => handleChange(index, event)}
                  placeholder="Food alergies, etc..."
                />
              </div>
            {tour_package_json?.price === 0 && <div className="space-y-1" style={{marginBottom: "10px", color: "#2684ff"}}>
                              <label
                              onClick={() => {
                                filterContext.setMostrarTerminos(true);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              style={{cursor: "pointer"}}
                              >Terms and Conditions</label>
                              <br />
                              
                              <input
                                type="checkbox"
                                checked={terminos}
                                onChange={handleTerms}
                                style={{display: "block"}}
                              />
              </div>}
          </div>         
          </Tab.Panel>
        ))}
      </Tab.Panels>
      </Tab.Group>
      
      <Tab.Group>
      <div className="pt-8" style={{width: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
      {tour_package_json?.price > 0 && <h2 className="text-2xl lg:text-3xl font-semibold" style={{marginBottom: "20px"}}>
          Reservation
      </h2>}
      <div style={{display: "flex", justifyContent: "space-around", width: "100%"}}>
      <ButtonPrimary 
      onClick={async () => {
        localStorage.setItem("valores", JSON.stringify(valores));
        console.log("valores", valores)
        const todosTienenDatos = valores?.elementos.every((registro) => {
          return (
            registro.tipoDocumento.length > 0 &&
            registro.documento.length > 0 &&
            registro.nombre.length > 0 &&
            registro.correo.length > 0 &&
            registro.telefono.length > 0 &&
            registro.edad.length > 0 &&
            registro.paisOrigen.length > 0 
          );
        });

        if(todosTienenDatos && tour_package_json?.price < 0)
        {
          filterContext.setPagar(true);
        }
        else if(todosTienenDatos && tour_package_json?.price >= 0)
        {
            let reserva = localStorage.getItem("valores");
            let id_disponibilidad = localStorage.getItem("id_disponibilidad");
            let dataReserva = JSON.parse(reserva+"");
            let nacionalidad = localStorage.getItem("nationalilty")
            const todosTienenDatos = dataReserva?.elementos.every((registro) => {
              return (
                registro.tipoDocumento.length > 0 &&
                registro.documento.length > 0 &&
                registro.nombre.length > 0 &&
                registro.correo.length > 0 &&
                registro.telefono.length > 0 &&
                registro.indicativo.length > 0 &&
                registro.edad.length > 0 &&
                registro.paisOrigen.length > 0 
              );
            });
            
            try
            {
             if(todosTienenDatos)
             {
                  // if(terminos)
                  // {
                    setSpinner(true);
                    let codigos = [];
                    await Promise.all(
                      dataReserva?.elementos.map(async (el) => {
                        let data = {
                          payform_id: "3a0b48b4-ab1c-c7fc-45c1-d88a8a08a784",
                          tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "",
                          date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                          document_guest: el?.documento,
                          type_document_guest: el?.tipoDocumento,
                          name_guest: el?.nombre,
                          email_guest: el?.correo,
                          phone_guest: `${el?.indicativo}${el?.telefono}`,
                          age_guest: parseInt(el?.edad),
                          remark: el?.observaciones,
                          gross_amount: (tour_package_json?.price),
                          tax_amount: 0,
                          net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          status: 0,
                          country: el?.paisOrigen,
                          approved: "", 
                          status_transaction: "0",
                          channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                          channel_name: ""
                        };
  
                        const fecha = hora_cambio == "0001-01-01T00:00:00" ? new Date(tour_package_json?.date_schedul) : new Date(hora_cambio);
    
                        const hora = fecha.getHours().toString().padStart(2, '0');
                        const minutos = fecha.getMinutes().toString().padStart(2, '0');
                        const horaFormateada = `${hora}:${minutos}`;
  
                        let dataCorreo = {
                          asunto: "Approved",
                          nombre: el?.nombre,
                          email: el?.correo,
                          telefono: `${el?.indicativo}${el?.telefono}`,
                          documento: el?.documento,
                          nombrePaquete: tour_package_json?.tour_package,
                          descripcion: tour_descripcion,
                          punto_encuentro: tour_package_json?.meeting_point,
                          duracion: tour_package_json?.duration,
                          precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          recomendaciones: tour_package_json?.recommendations,
                          politicas_cancelacion: "Cancellation Policies: Does not apply",
                          fecha: fecha_sel,
                          hora: horaFormateada,
                          cantidad: (localStorage.getItem("guest_number")+"")
                          // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                        }
                        
                        sendEmail(dataCorreo);
                        let correoCapture = {...dataCorreo, email: "Reservas@capturecolombiatours.com"}
                        sendEmail(correoCapture);
                        if((tour_parse && tour_parse.tour && tour_parse.tour.alternate_email) && tour_parse.tour.alternate_email.length > 0)
                        {
                          let correoCaptureAlternate = {...dataCorreo, email: tour_parse.tour.alternate_email}
                          sendEmail(correoCaptureAlternate);
                        }
                        return reserveDetails(data).then((res) => {
                          codigos.push(res);
                        });
                      })
                    );
                    let nombresReserva = dataReserva?.elementos.map((el) => el.nombre);
                    localStorage.setItem("nombresReserva", nombresReserva.toString());
                    localStorage.setItem("codigos_reserva", JSON.stringify(codigos));
                    codigos.map((item) => {
                      approvedReserve(item, "Aprobado")
                    })
                    let dataVacia = {
                      guestLabel: [],
                      elementos: []
                    };

                    const dataJSON = JSON.stringify(dataVacia);
                    localStorage.setItem("valores", dataJSON);
                    localStorage.removeItem("theme");
                    localStorage.removeItem("cupos_disponibles");
                    localStorage.removeItem("tour_title");
                    localStorage.removeItem("cantidad_paquetes_fecha");
                    localStorage.removeItem("lastPath");
                    localStorage.removeItem("tour_description");
                    localStorage.removeItem("id_disponibilidad");
                    localStorage.removeItem("urlQuery");
                    localStorage.removeItem("canal");
                    navigate('/pay-done');
                    setSpinner(false);
                    Swal.fire({
                      title: 'Reserve',
                      text: 'Your reservation has been approved',
                      icon: 'success',
                      confirmButtonText: 'Accept'
                    })
                  // }
                  // else
                  // {
                  //   Swal.fire({
                  //     title: 'Terms and Conditions',
                  //     text: 'You must accept terms and conditions',
                  //     icon: 'error',
                  //     confirmButtonText: 'Accept'
                  //   })
                  // }                  
             }
             else{
              setSpinner(false);
              Swal.fire({
                title: 'Erro',
                text: 'Please, fill all the information required',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
             }
            }
            catch(err)
            {
              setSpinner(false);
              Swal.fire({
                title: 'Error',
                text: 'Error creating reservation',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
            }
        }
        else
        {
          Swal.fire({
            title: 'Error',
            text: 'Please, fill all the information required',
            icon: 'error',
            confirmButtonText: 'Accept'
          })
        }

      }}
      >
        <span style={{fontSize: "18px"}}>{tour_package_json?.price >= 0 ? "Reserve" : "Credit Card"}</span>
      </ButtonPrimary>
      {(tour_package_json?.price > 0 && localStorage.getItem("canal") != null) && <ButtonPrimary 
      onClick={async () => {
        localStorage.setItem("valores", JSON.stringify(valores));
        const todosTienenDatos = valores?.elementos.every((registro) => {
          return (
            registro.tipoDocumento.length > 0 &&
            registro.documento.length > 0 &&
            registro.nombre.length > 0 &&
            registro.correo.length > 0 &&
            registro.telefono.length > 0 &&
            registro.edad.length > 0 &&
            registro.paisOrigen.length > 0 
          );
        });

        if(todosTienenDatos)
        {
            let reserva = localStorage.getItem("valores");
            let id_disponibilidad = localStorage.getItem("id_disponibilidad");
            let dataReserva = JSON.parse(reserva+"");
            let nacionalidad = localStorage.getItem("nationalilty")
            const todosTienenDatos = dataReserva?.elementos.every((registro) => {
              return (
                registro.tipoDocumento.length > 0 &&
                registro.documento.length > 0 &&
                registro.nombre.length > 0 &&
                registro.correo.length > 0 &&
                registro.telefono.length > 0 &&
                registro.indicativo.length > 0 &&
                registro.edad.length > 0 &&
                registro.paisOrigen.length > 0 
              );
            });
            
            try
            {
             if(todosTienenDatos)
             {
                    setSpinner(true);
                    let codigos = [];
                    await Promise.all(
                      dataReserva?.elementos.map(async (el) => {
                        let data = {
                          payform_id: "3a0b3585-c963-f191-62e9-969012a56415",
                          tourdetail_id: id_disponibilidad !== null ? id_disponibilidad : "00000000-0000-0000-0000-000000000000",
                          date_reserve: new Date(fecha_sel !== null ? fecha_sel : new Date()),
                          document_guest: el?.documento,
                          type_document_guest: el?.tipoDocumento,
                          name_guest: el?.nombre,
                          email_guest: el?.correo,
                          phone_guest: `${el?.indicativo}${el?.telefono}`,
                          age_guest: parseInt(el?.edad),
                          remark: el?.observaciones,
                          gross_amount: (tour_package_json?.price),
                          tax_amount: 0,
                          net_amount: (tour_package_json?.price) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          status: 0,
                          country: el?.paisOrigen,
                          approved: "", 
                          status_transaction: "0",
                          channel_id: localStorage.getItem("canal") || "00000000-0000-0000-0000-000000000000",
                          channel_name: ""
                        };
                        console.log("datass", data);
                        const fecha = new Date(tour_package_json?.date_schedul);
    
                        const hora = fecha.getHours();
                        const minutos = fecha.getMinutes();
                        const horaFormateada = `${hora}:${minutos}`;
  
                        let dataCorreo = {
                          asunto: "Approved",
                          nombre: el?.nombre,
                          email: el?.correo,
                          telefono: `${el?.indicativo}${el?.telefono}`,
                          documento: el?.documento,
                          nombrePaquete: tour_package_json?.tour_package,
                          descripcion: tour_descripcion,
                          punto_encuentro: tour_package_json?.meeting_point,
                          duracion: tour_package_json?.duration,
                          precio: (tour_package_json?.price * parseInt(localStorage.getItem("guest_number")+"")) - (parseInt(localStorage.getItem("descuento_cupon")+"") * tour_package_json?.price / 100),
                          recomendaciones: tour_package_json?.recommendations,
                          politicas_cancelacion: tour_package_json?.policies_cancellation || "Cancellation Policies:",
                          fecha: fecha_sel,
                          hora: hora_cambio == "0001-01-01T00:00:00" ? horaFormateada : hora_cambio,
                          cantidad: (localStorage.getItem("guest_number")+"")
                          // cantidad: (cantidad_personas_cambio == "0" || cantidad_personas_cambio == "") ? tour_package_json?.person_limit : cantidad_personas_cambio,
                        }
                        console.log("correo", dataCorreo);
                        sendEmail(dataCorreo);
                        let correoCapture = {...dataCorreo, email: "Reservas@capturecolombiatours.com"}
                        sendEmail(correoCapture);
                        return reserveDetails(data).then((res) => {
                          codigos.push(res);
                        });
                      })
                    );
                    let nombresReserva = dataReserva?.elementos.map((el) => el.nombre);
                    localStorage.setItem("nombresReserva", nombresReserva.toString());
                    localStorage.setItem("codigos_reserva", JSON.stringify(codigos));
                    codigos.map((item) => {
                      approvedReserve(item, "Aprobado")
                    })
                    let dataVacia = {
                      guestLabel: [],
                      elementos: []
                    };
                    localStorage.setItem("Reserva_aprobada", "Approved");
                    const dataJSON = JSON.stringify(dataVacia);
                    localStorage.setItem("valores", dataJSON);
                    localStorage.removeItem("theme");
                    localStorage.removeItem("cupos_disponibles");
                    localStorage.removeItem("tour_title");
                    localStorage.removeItem("cantidad_paquetes_fecha");
                    localStorage.removeItem("lastPath");
                    localStorage.removeItem("tour_description");
                    localStorage.removeItem("id_disponibilidad");
                    localStorage.removeItem("urlQuery");
                    navigate('/pay-done');
                    setSpinner(false);
                    Swal.fire({
                      title: 'Reserved',
                      text: 'Your reservation has been approved',
                      icon: 'success',
                      confirmButtonText: 'Accept'
                    })              
             }
             else{
              setSpinner(false);
              Swal.fire({
                title: 'Erro',
                text: 'Please, fill all the information required',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
             }
            }
            catch(err)
            {
              setSpinner(false);
              Swal.fire({
                title: 'Error',
                text: 'Error creating reservation',
                icon: 'error',
                confirmButtonText: 'Accept'
              })
            }
        }
        else
        {
          Swal.fire({
            title: 'Error',
            text: 'Please, fill all the information required',
            icon: 'error',
            confirmButtonText: 'Accept'
          })
        }
      }}
      >
        <span style={{fontSize: "18px"}}>Cash</span>
      </ButtonPrimary>}
      </div>
      </div>
      </Tab.Group>
          </div>
          
        </div>}
     
    </div>
  );
};

export default FormPayGuest;
