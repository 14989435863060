import React, { FC, useState, useContext, useEffect } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { Imagen } from "data/types";
import FilterContext from "helpers/filter/FilterContext";
import axios, { AxiosRequestConfig } from 'axios';
import { useNavigate } from "react-router-dom";
import SpinnerFull from "shared/Spinner/SpinnerFull";
import { fetchDollars } from "api/backendApi";

 
const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

interface Paquete {
  tour_id: string;
  tourGuide_id: string;
  date_schedul: string;
  price: number;
  remark: string;
  includes: string;
  no_includes: string;
  meeting_point: string;
  policies_cancellation: string;
  recommendations: string;
  person_operator: string;
  duration: string;
  status: number;
  comission: number;
  person_limit: number;
  tour_package: string;
  enable_days: string;
  description: string;
  lastModificationTime: string;
  lastModifierId: string;
  creationTime: string;
  creatorId: string;
  id: string;
}

interface PackageDetail {
  creationTime: string,
  creatorId: string,
  date: string,
  id: string,
  hour: string;
  person_limit: string;
  id_tour: string,
  id_tourDetail: string,
  lastModificationTime: string;
  lastModifierId: string;
  name: string
}

interface Paquetes {
  coupons: [],
  guides: [],
  imagenes: [],
  package_details: [],
  paquetes: [],
  tours: [],
}

interface Package {
  coupons: [],
  guides: [],
  imagenes: any[],
  package_details: any[],
  paquetes: any[],
  tour: any[],
}

interface Imagenes {
  creationTime: string,
  creatorId: string,
  id: string,
  image: string,
  lastModificationTime: string,
  lastModifierId: string,
  order: number
  status: number,
  tour_id: string,
}

interface Tours {
  city_id: string,
code: string,
coupons_id: string,
creationTime: string,
creatorId: string,
description: string,
id: string,
lastModificationTime: string,
lastModifierId: string,
name: string,
status: number,
tipo_tour_id: string,
}

interface Reviews {
  titulo: string,
  texto: string,
  url: string
}

const reviewsLista : Reviews[] = [
  {
    titulo: "Attention to Detail",
    texto: "We are a reliable and experienced tour agency, focused on complementing memorable moments and showcasing the best of our culture and our people.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fhand.png?alt=media&token=a02b29e0-a54d-47c2-bbb5-0b869f3c7212"
  },
  {
    titulo: "Lots Of Choices",
    texto: "We offer a wide range of group & private experiences to unmissable and out of the beaten track spots.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fstar.png?alt=media&token=6ee31251-c4fc-436f-bdb3-331939af634d"
  },
  {
    titulo: "Passionate Tour Guides",
    texto: "We are cristal clear that the only way to make you feel our esence is by sharing it with expert local hosts.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fmap.png?alt=media&token=a8a35c82-90a8-40d7-887e-27233befebe7"
  },
  {
    titulo: "Clear Info & Easy Booking",
    texto: "We’ll give you all the details so you can easily figure out what’s the best choice according to your interests. We’ll take care of the rest.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fcell.png?alt=media&token=dd7709d1-dcbc-4e4d-8f84-4a5a42034096"
  }
]

export interface SectionGridHasMapProps {}

  const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [imagenes_paquetes, setImagenes_paquetes] = useState(false);
  const filterContext = useContext(FilterContext);
  const [dolar, setDolar] = useState<number>(0);
  const [datosPaquetes, setDatosPaquetes] = useState<Paquetes>({
    coupons: [],
    guides: [],
    imagenes: [],
    package_details: [],
    paquetes: [],
    tours: [],
  });
  const [cambioEstado, setcambioEstado] = useState(false);
  const [noPaquetes, setNoPaquetes] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setcambioEstado(filterContext.state);
    if(urlQuery === "true")
    {
      fetchData();
    }
  }, [filterContext.state]);

  useEffect(() => {
    fetchDollars().then(res => {
      setDolar(parseInt(res.data.value));
    })
  }, []);

  let local_tour =  localStorage.getItem("tour");
  let informacion = localStorage.getItem(local_tour+"")
  let dia_selec =  localStorage.getItem("dia_seleccionado");
  let fechaSeleccionada = localStorage.getItem("fecha_seleccionada");
  let urlQuery = localStorage.getItem("urlQuery");
  const informacionJSON = JSON.parse(informacion+"");

  let images_data  :  string[]  = [];
  informacionJSON && informacionJSON.imagenes.forEach((element : Imagen) => {
    images_data.push(element.image);
  });

  const fetchData = async () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = fechaActual.getMonth() + 1;
    const day = fechaActual.getDate();
    let fecha = localStorage.getItem("fecha_seleccionada") === null ? `${year}-${(month.toString().length == 1) && "0"}${month}-${day} `: localStorage.getItem("fecha_seleccionada");
    const config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://capturecolombia.azurewebsites.net/api/app/base/tour-by-date',
      headers: {},
      params: {
        date: fecha
      }
    };

    try {
      const response = await axios.request(config);
      let paquetes = response.data;
      setDatosPaquetes(paquetes);
      localStorage.setItem("cantidad_paquetes_fecha", paquetes.package_details.length)

    } catch (error) {
      console.log(error);
    }

  };

  const obternerImagenes = (id: string) => {
    let datos = datosPaquetes.imagenes.filter((el: Imagenes) => el.tour_id === id);
    let imagenes = datos.map((el: Imagenes) => el.image)
    return imagenes
  };

  const fetch = async (id: string) => {

    datosPaquetes.tours.map( (el: Tours) => {
      if(el.id === id)
      {
        let tour = datosPaquetes.tours.filter( (el: any) => el.id === id);
        let datos: Package = {
          coupons: datosPaquetes.coupons,
          guides: datosPaquetes.guides,
          imagenes: datosPaquetes.imagenes.filter( (el: any) => el.tour_id === id),
          package_details: datosPaquetes.package_details.filter( (el: any) => el.tour_id === id),
          paquetes: datosPaquetes.paquetes.filter( (el: any) => el.tour_id === id),
          tour: tour[0],
        }

        localStorage.setItem("tour_title",el.name);
        localStorage.setItem("tour_description",el.description);

        localStorage.setItem("tour", id);
        localStorage.setItem(id, JSON.stringify(datos));

      }
    })

  };



  return (
    <div>
      {filterContext.Loader && <SpinnerFull texto="Loading data..."/>}
      <div className="relative flex min-h-screen">

        <div className="min-h-screen w-full flex-shrink-0 xl:px-8 " style={{position: "relative"}}>

        <div 
        className="posicionCaminoPies"
        style={{
                  backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2Fcamino%20pies.png?alt=media&token=19da2699-601b-42c7-8041-e565e0f3756e)",
                  backgroundSize: 'cover',
                  backgroundRepeat: 'repeat-y', 
                  width: "101.5%",
                  height: "500px",
                  position: "absolute",
        }}>
                    
        </div>
          <Heading2 />
          <div className="grid grid-cols-1 gap-8" style={{zIndex: 100}}>
            {urlQuery === "false" ? informacionJSON.paquetes.map((item : Paquete) => {
              return informacionJSON.package_details.map((registro : PackageDetail, index: number) => {
                const fechaHoraActual = new Date();
                const horaActual = fechaHoraActual.getHours();
                const minutosActuales = fechaHoraActual.getMinutes();
                if(item.id === registro.id_tourDetail)
                {
                  const fechaObjetivo = registro.hour == "0001-01-01T00:00:00" ? item?.date_schedul : registro.hour;
                  const fechaHoraObjetivo = new Date(fechaObjetivo);
                  const horaObjetivo = fechaHoraObjetivo.getHours();
                  const minutosObjetivo = fechaHoraObjetivo.getMinutes();
                  if(fechaSeleccionada && (new Date().getDate() == parseInt(fechaSeleccionada.substring(fechaSeleccionada.length - 2))))
                  {
                    if (horaActual < horaObjetivo || (horaActual === horaObjetivo && minutosActuales < minutosObjetivo))
                    {
                     return <div
                     key={index}
                     onClick={() => {fetch(registro.id_tour)}}
                     onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                     onMouseLeave={() => setCurrentHoverID((_) => -1)}
                   >
                     <StayCardH  
                     data={item} 
                     images_data={obternerImagenes(registro.id_tour)} 
                     idDisponibilidad={registro.id}
                     availability={datosPaquetes.package_details.length}
                     dolar={dolar}
                     hour={registro.hour}
                     limit_person={registro.person_limit}
                     />
                   </div>
                    }
                  }
                  else{
                    return <div
                     key={index}
                     onClick={() => {fetch(registro.id_tour)}}
                     onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                     onMouseLeave={() => setCurrentHoverID((_) => -1)}
                   >
                     <StayCardH  
                     data={item} 
                     images_data={obternerImagenes(registro.id_tour)} 
                     idDisponibilidad={registro.id}
                     availability={datosPaquetes.package_details.length}
                     dolar={dolar}
                     hour={registro.hour}
                     limit_person={registro.person_limit}
                     />
                   </div>
                  }
                  
                }
              })
            })
            :
            Object.keys(datosPaquetes).length > 0 && datosPaquetes.paquetes.map((item : Paquete) => {
                return datosPaquetes.package_details.map((registro : PackageDetail, index: number) => {
                  console.log("paquetes res: ", registro)
                  const fechaHoraActual = new Date();
                  const horaActual = fechaHoraActual.getHours();
                  const minutosActuales = fechaHoraActual.getMinutes();
                  if(item.id === registro.id_tourDetail)
                  {
                    const fechaObjetivo = registro.hour == "0001-01-01T00:00:00" ? item?.date_schedul : registro.hour;
                    const fechaHoraObjetivo = new Date(fechaObjetivo);
                    const horaObjetivo = fechaHoraObjetivo.getHours();
                    const minutosObjetivo = fechaHoraObjetivo.getMinutes();
                    if(fechaSeleccionada && (new Date().getDate() == parseInt(fechaSeleccionada.substring(fechaSeleccionada.length - 2))))
                    {
                      if (horaActual < horaObjetivo || (horaActual === horaObjetivo && minutosActuales < minutosObjetivo))
                      {
                       return <div
                       key={index}
                       onClick={() => {
                        fetch(registro.id_tour)
                        localStorage.setItem("hora-cambio", registro.hour);
                        localStorage.setItem("cantidad-personas-cambio", registro.person_limit);
                      }}
                       onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                       onMouseLeave={() => setCurrentHoverID((_) => -1)}
                     >
                       <StayCardH  
                       data={item} 
                       images_data={obternerImagenes(registro.id_tour)} 
                       idDisponibilidad={registro.id}
                       availability={datosPaquetes.package_details.length}
                       dolar={dolar}
                       hour={registro.hour}
                       limit_person={registro.person_limit}
                       />
                     </div>
                      }
                    }
                    else{
                      return <div
                       key={index}
                       onClick={() => {
                        fetch(registro.id_tour)
                        localStorage.setItem("hora-cambio", registro.hour);
                        localStorage.setItem("cantidad-personas-cambio", registro.person_limit);
                      }}
                       onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                       onMouseLeave={() => setCurrentHoverID((_) => -1)}
                     >
                       <StayCardH  
                       data={item} 
                       images_data={obternerImagenes(registro.id_tour)} 
                       idDisponibilidad={registro.id}
                       availability={datosPaquetes.package_details.length}
                       dolar={dolar}
                       hour={registro.hour}
                       limit_person={registro.person_limit}
                       />
                     </div>
                    }
                    
                  }
                })
              })
            }
          </div>
          <div className="grid grid-cols-1 gap-8" style={{marginBottom: "100px"}}>
          {Object.keys(datosPaquetes).length === 0 && 
          <p style={{fontSize: "20px"}}>There are no packages available for this date.</p>}  
          </div>

          <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}
        >
         {reviewsLista.map( el => 
                  <div style={{position: "relative", padding: "40px"}}>
                  <img style={{position: "absolute", width: "80px", top: "0", left: "0"}} src={el.url} alt="Icono" />
                  <div style={{
                    background: "white", 
                    borderRadius: "15px", 
                    width: "270px", 
                    padding: "30px 20px 20px 20px", 
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", 
                    minHeight: "280px",
                    }}>
                      <p style={{color: "black", fontSize: "30px", margin: "10px 0", fontWeight: "600"}}>{el.titulo}</p>
                      <p style={{color: "black", fontSize: "16px"}}>
                          {el.texto}
                      </p>
                  </div>
                  </div>
            )}
        </div>

        </div>

      </div>
    </div>
  );
};

export default SectionGridHasMap;
