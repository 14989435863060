import React, { Fragment, useState, useContext, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "./type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import FilterContext from "helpers/filter/FilterContext";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
}) => {

  let valorStr = localStorage.getItem("guest_number");
  const filterContext = useContext(FilterContext);
  
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(valorStr == null ? 0 : parseInt(valorStr));
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  const [cambioEstado, setcambioEstado] = useState(false);

  useEffect(() => {
    setcambioEstado(filterContext.state);
  }, [filterContext.state])

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  let totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

    localStorage.setItem("guest_number",totalGuests+"");
  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (

        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
            style={{color: "black"}}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div 
              className="text-neutral-300"
              style={{color: "#e3488e"}}
              >
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <div style={{display: "flex"}}>
                  <span className="block xl:text-lg font-semibold" style={{marginRight: "5px"}}>
                    {totalGuests} 
                  </span>
                  <span 
                  className="block xl:text-lg font-semibold">
                    Guests
                  </span>
                </div>
                <span 
                className="block mt-1 text-sm text-neutral-400 leading-none font-light"
                style={{color: "black"}}
                >
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    setGuestInfantsInputValue(0);
                  }}
                />
              )}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div
              onClick={() => {filterContext.setState(!filterContext.state)}}
              className="pr-2 xl:pr-4">
                <ButtonSubmit 
                href={buttonSubmitHref} 
                />
              </div>
            )}
          </div>

          {open && (
            <div 
            className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-0"
            style={{background: "#a8a6a6", height: "50px", borderRadius: "20px"}}
            ></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel 
            className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl"
            style={{background: "#c5c5c5"}}
            >
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={100}
                min={1}
                label="Guest"
                desc=""
              />
              
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
