import React, { FC, useEffect, useState, useContext } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Imagen, Paquete } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { NumericFormat } from 'react-number-format';
import { fetchData, fetchDollars } from "api/backendApi";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Spinner from "shared/Spinner/Spinner";
import FilterContext from "helpers/filter/FilterContext";
import { BsFillTriangleFill, BsTriangle } from 'react-icons/bs';
import { IoIosPeople } from 'react-icons/io';
import { UsersIcon, MapPinIcon, NoSymbolIcon, ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid";

export interface StayCardHProps {
  className?: string;
  data?: Paquete;
  images_data :  string[];
  idDisponibilidad: string;
  availability: number,
  nameTour?: string,
  dolar: number,
  hour: string,
  limit_person: string
}

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data,
  images_data,
  idDisponibilidad,
  dolar,
  availability,
  nameTour,
  hour, 
  limit_person
}) => {
  const filterContext = useContext(FilterContext);
  const [reservas, setReservas] = useState<any | null>(null);
  const [cuposDisponibles, setCuposDisponibles] = useState<any | null>([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSpinner(true);
    fetchData("reserve-details").then(res => {
      console.log("datacard:", res)
      let cuposArreglo = res.filter((ele: any) => ele.tourdetail_id === idDisponibilidad)
      setCuposDisponibles(cuposArreglo);
      setReservas(res);
    });
    setTimeout(() => {
        setSpinner(false);
    }, 2000);
  }, []);
 
  const renderSliderGallery = () => {
    return (
      <div 
      onClick={(e) => e.stopPropagation()}
      className="relative flex-shrink-0 w-full md:w-72 " 
      style={{borderRadius: "10px", overflow: "hidden"}}
      >
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={images_data}
          uniqueID={`StayCardH_${"prueba123"}`}
          href={""}
        />
       
        {false && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  let Dollars = data && Math.ceil(data?.price / dolar).toFixed(2);

  const renderTienIch = () => {
    return (
      <div className="sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-start space-x-3">
            <IoIosPeople style={{fontSize: "25px", color: "#fff741"}}/>
            <span>
            <strong>Availability:</strong> {" "+ (data && (limit_person == "0" || limit_person == "") ? data?.person_limit - cuposDisponibles.length : parseInt(limit_person) - cuposDisponibles.length )}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <ClockIcon style={{width: "20px", color: "#fff741"}}/>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  <strong>Duration:</strong> {" "+ data?.duration + ""}
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-start space-x-3">
            <span><MapPinIcon style={{width: "20px", color: "#fff741"}}/></span>
            <span 
            className="text-sm text-neutral-500 dark:text-neutral-400"
            style={{overflow: "hidden", textOverflow: "ellipsis"}}
            >
              <strong>Meeting Point:</strong>{" "+data?.meeting_point}
            </span>
          </div>

          <div className="flex items-center space-x-3">
            <ClockIcon style={{width: "20px", color: "#fff741", transform: "rotate(270deg)"}}/>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              <strong>Hour: </strong>{hour == "0001-01-01T00:00:00" ? data?.date_schedul.split("T")[1].substring(0, 5) : hour.split("T")[1].substring(0, 5)}
            </span>
          </div>
          
        </div>
        <div className="space-y-3">
          <div className="flex items-start space-x-3">
            <span><NoSymbolIcon style={{width: "20px", color: "#fff741"}}/></span>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
            <strong>Not Included:</strong>{" "+data?.no_includes}
            </span>
          </div>
         
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div 
      className="flex-grow p-3 sm:p-5 flex flex-col"
      style={{cursor: "pointer"}}
      >
        <div className="space-y-2">
           
          <div className="flex items-center space-x-2">
            
            <h2 className="titleCards">
              <span>{data?.tour_package.split("-")[0]}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>

        <div style={{marginBottom:"10px"}} >{data?.remark}</div>
        <div className="flex justify-between items-end">  
          <div style={{display: "flex"}}>
          <span><CurrencyDollarIcon style={{width: "20px", color: "#fff741"}}/></span>
          <span className="text-base font-semibold text-secondary-500">
          <strong>Pesos: </strong><NumericFormat value={data?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />   
          </span>
          </div>
          <div style={{display: "flex"}}>
          <span><CurrencyDollarIcon style={{width: "20px", color: "#fff741"}}/></span>
          <span className="text-base font-semibold text-secondary-500">
          <strong>Dollars: </strong><NumericFormat value={Dollars} displayType={'text'} thousandSeparator={true} prefix={'$'} />          
          </span>
          </div>
          <div style={{display: "flex", justifyContent: "start", width: "33%"}}>
        <ButtonPrimary 
              onClick={()=>{
                let guest_number = localStorage.getItem("guest_number");
                localStorage.setItem("id_disponibilidad", idDisponibilidad);
                let cupos = (data && (limit_person == "0" || limit_person == "") ? data?.person_limit - cuposDisponibles.length : parseInt(limit_person) - cuposDisponibles.length );
                if(guest_number !== null)
                {
                  if(parseInt(guest_number) <= (cupos ? cupos : 0) && parseInt(guest_number) > 0)
                  {
                    let cupos = data && data?.person_limit - cuposDisponibles.length;
                    localStorage.setItem("cupos_disponibles", JSON.stringify(cupos));
                    localStorage.setItem("paquete_seleccionado",JSON.stringify(data));
                    localStorage.setItem("descuento_cupon", "0");
                    filterContext.setLoader(true)
                    fetchDollars().then(res => {
                      let dollar = data && Math.ceil(data?.price / res.data.value).toFixed(2);
                      localStorage.setItem("precio_dolares", JSON.stringify(dollar));
                      filterContext.setLoader(false);
                      navigate("/listing-stay-detail");
                    })
                  }
                  else
                  {
                    Swal.fire({
                      title: 'Error',
                      text: 'Sorry, we do not have more spaces available for that experience or you need to choose a number of guest',
                      icon: 'error',
                      confirmButtonText: 'accept'
                    });
                  }
                }
             }}
        ><span className="btnBookNow">Book Now</span></ButtonPrimary>
        </div>
        </div>
      </div>
    );
  };

  return (
    <div  
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
      style={{border: "1px solid white", padding: "10px", background: "black", position: "relative"}}
    >
      <div className="triangulos" >
          <BsFillTriangleFill className="trianguloUno"/>
          <BsFillTriangleFill className="triangulo"/>
          <BsTriangle className="triangulo" style={{color: "#930707"}}/>
      </div>
      {/* <Link to={"/listing-stay-detail"} className="absolute inset-0"></Link> */}
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {spinner && <Spinner texto="Loading data..." />}
        {!spinner && renderSliderGallery()}
        {!spinner && renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
