import axios, { AxiosRequestConfig } from 'axios';
import { Guid } from 'guid-typescript';

interface Correo {
  asunto: string,
  nombre: string, 
  email: string, 
  documento: string, 
  nombrePaquete: string, 
  descripcion: string,         
  punto_encuentro: string,
  duracion: string,
  precio: number,
  recomendaciones: string,
  politicas_cancelacion: string,
  fecha: string,
  hora: string, 
  cantidad: string, 
}

interface WompiData {
  tarjeta: {
    number: string;
    exp_month: string;
    exp_year: string;
    cvc: string;
    card_holder: string;
  };
  customer_email: string;
  request_transaction: {
    acceptance_token: string;
    amount_in_cents: number;
    currency: string;
    customer_email: string;
    payment_method: {
      type: string;
      token: string;
      installments: number;
    };
    payment_source_id: number;
    redirect_url: string;
    reference: string;
    customer_data: {
      phone_number: string;
      full_name: string;
      legal_id: string;
      legal_id_type: string;
    };
    shipping_address: {
      address_line_1: string;
      address_line_2: string;
      country: string;
      region: string;
      city: string;
      name: string;
      phone_number: string;
      postal_code: string;
    };
  };
  reserver_detail_id: string;
}

export const fetchData = async (url: string) => {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: 'https://capturecolombia.azurewebsites.net/api/app/base/' + url,
      headers: {}
    }
    try {
        const response = await axios.request(config);
        let data = response.data;  
        return data;  
      } catch (error) {
        return error;
      }
};

export const approvedReserve = async (id: string, dato: string) => {
  let idReplace = id.replace(/^"|"$/g, '');
  const idSend = Guid.parse(idReplace);
  const config: AxiosRequestConfig = {
    method: 'post',
    url: `https://capturecolombia.azurewebsites.net/api/app/base/${idSend}/edit-reserve-detail-approved`,
    headers: {},
    params: {approved: dato}
  }
  try {
      const response = await axios.request(config);
      let data = response;  
      return data;  
    } catch (error) {
      return error;
    }

};

export const fetchDollars = async () => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: 'https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/TRMHoy',
    headers: {}
  }
  try {
      const response = await axios.request(config);
      let data = response.data;  
      return data;  
    } catch (error) {
      return error;
    }
};

export const getCountries = async () => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: 'https://restcountries.com/v3.1/all',
    headers: {}
  }
  try {
      const response = await axios.request(config);
      let data = response.data;  
      return data;  
    } catch (error) {
      return error;
    }
};

export const sendEmail = async (data: Correo) => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://capturecolombia.azurewebsites.net/api/app/base/enviar-correo',
    headers: {},
    params: data
  }
  try {
      const response = await axios.request(config);
      let data = response;  
      console.log("resp correo", response)
      return data;  
    } catch (error) {
      return error;
    }
};

export const sendWompi = async (data: WompiData) => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://capturecolombia.azurewebsites.net/api/app/base/set-wompi-tx',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify(data)
  }
  try {
      const response = await axios.request(config);
      let data = response;  
      return data;  
    } catch (error) {
      return error;
    }
};

export const validateWompi = async (id: string) => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: `https://capturecolombia.azurewebsites.net/api/app/base/validate-wompi-tX/${id}`,
    headers: { 
      'accept': 'text/plain'
    },
  }
  try {
      const response = await axios.request(config);
      let data = response;  
      return data;  
    } catch (error) {
      return error;
    }

};

