import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24",
}) => {
  return (
   <>
      {img ? (
        <img
        className="hidden max-h-12 dark:block"
        
          style={{width: '15%', height: '20%'}}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}

{logoLightImg ? (
        <img
        className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          style={{width: '15%', height: '20%'}}
          src={logoLightImg}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}

      
    </>
  );
};

export default Logo;
