import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import FilterContext from "helpers/filter/FilterContext";

const Terminos = () => {
    const filterContext = useContext(FilterContext);
  return (
        <>
        {filterContext.mostrarTerminos &&
            <div style={{padding: "40px", background: "#232323", position: "absolute", top: 0, left: 0, width: "100%", zIndex: 9999}}>
            <div 
            onClick={() => filterContext.setMostrarTerminos(false)}
            style={{display: "flex", alignItems: "center", marginBottom: "20px", color: "#2680f5", cursor: "pointer"}}>
                <ChevronLeftIcon 
                style={{width: "40px", height: "40px"}}
                /> 
                <p>Go back</p>
            </div>
            <Card style={{borderColor: "#fff"}}>
              <div style={{whiteSpace: "pre-line"}}>
              Capture Colombia Tours - OPERATING AGENCY AND TRAVEL AGENCY.
              <br/>
                <br/>
                CAPTURE COLOMBIA TOURS, registered trademark before the Superintendence of Industry and Commerce, by society is a company, legally constituted in Colombia, with NIT number 901561535-3 and RNT 82020. Its main corporate purpose is to create, develop, commercialize, organize and promote tourism plans to be operated by the same society; It operates within the country tourist plans programmed by foreign travel agencies and the country, provides guidance services and provides specialized equipment when the activity requires it. It also organizes, promotes and markets national tourism plans, to be operated by the same company or by a different operating agency. The aforementioned company operates trips in Colombia, directing its offer to clients - CLIENTES - both domestic and foreign.
                <br/>
                <br/>
                ACCEPTANCE OF TERMS AND CONDITIONS BY THE CLIENT
                When accessing the website or with our staff to any of the services offered by CAPTURE COLOMBIA TOURS, it will be considered that THE CLIENT is fully aware of the content of the offers made by CAPTURE COLOMBIA TOURS, and that he knows and is well informed of the weather conditions, mode and place of each of them when requesting the service. In case of doubt, you should contact us before taking any service, to the email capturetours@lospatioshb.com, since once the service is reserved, it cannot be canceled or modified unless the exceptions described above apply ahead. Once the service has been accepted by CAPTURE COLOMBIA TOURS, it will be up to the latter, exclusively, to interpret the content of the offers presented on the website or by our staff with the support of the clarification communications sent at any time to the respective CLIENT. In any case, the CLIENT declares to expressly accept any contract by means of which CAPTURE COLOMBIA TOURS decides to regulate these terms and conditions.
                The entry, registration, reservation and purchase through the CAPTURE COLOMBIA TOURS  website capturecolombiatours.com or by our staff is understood as a tacit acceptance of these terms and conditions.
                By accepting these Terms and Conditions it is presumed that THE CLIENT is aware of the level of risk involved in the activities offered by CAPTURE COLOMBIA TOURS and that they are freely accepted by THE CLIENT, who assumes full responsibility for the damages that he causes himself or to third parties during the execution of said activities.
                <br/>
                <br/>
        
                REQUIREMENTS TO BE FULFILLED BY THE SERVICE TAKER
                CLIENTES who are not over 18 years of age must be accompanied by their parents, who will sign all the documents in their name or by a responsible adult duly authorized in writing by the person in charge.
                <br/>
                <br/>
                SERVICES OFFERED THROUGH CAPTURE COLOMBIA AGENCY OR THE WEB CAPTURECOLOMBIATOURS.COM offers the CLIENT the following services through its website and the office:
                i. Services marketed and operated exclusively by CAPTURE COLOMBIA TOURS; ii. Services marketed by CAPTURE COLOMBIA TOURS, but whose operation will be in charge of THIRD operators affiliated with CAPTURE COLOMBIA TOURS; and iii. Services marketed and operated by THIRD PARTIES, where CAPTURE COLOMBIA TOURS will act as a commercial agent and will collaborate with the CLIENT in all procedures before THIRD PARTIES; iv. Services marketed by CAPTURE COLOMBIA TOURS and operated by CAPTURE COLOMBIA TOURS in the company of THIRD operators.
                To access any of the above services, THE CLIENT must request CAPTURE COLOMBIA TOURS the service of their choice by completing and sending the service request formats established by CAPTURE COLOMBIA TOURS on the website or through the mail, called telephone or any means that allows you to know CAPTURE COLOMBIA TOURS the trip desired by THE CLIENT. However there are trips previously designed by CAPTURE COLOMBIA TOURS, EL CLIENT can design your trip according to your preferences.
                For the purposes of this document, THE CLIENT and CAPTURE COLOMBIA TOURS will understand that there are two kinds of offers:
                i. TYPE OFFERS: These are the offers offered by CAPTURE COLOMBIA TOURS through its website and on which THE CLIENT accepts the content and conditions expressly established therein.
                ii. ADJUSTMENT OFFERS - CUSTOMIZED TRIPS: These are the offers that are particularly designed by CAPTURE COLOMBIA TOURS for a particular interested CLIENT, and that exceed, modify or complement the type offers.
                <br/>
                <br/>
                RESERVATION OF SERVICES
                To make a reservation for any service, the CLIENT must complete and submit the “RESERVATION FORM” or other “FORMATS” established by CAPTURE COLOMBIA TOURS on its website and must make an initial payment agreed with CAPTURE COLOMBIA TOURS, which for all legal effects, service orders will be considered in favor of CAPTURE COLOMBIA TOURS.
                Only the reservation of the service by the CLIENT will be understood as perfected, once CAPTURE COLOMBIA TOURS accepts the service request sent by THE CLIENT and an initial payment has been made. CAPTURE COLOMBIA TOURS reserves the right not to accept service orders whose CLIENTES do not comply with the requirements demanded by the company or whenever the service requested by THE CLIENT exceeds CAPTURE COLOMBIA TOURS's capabilities in any way.
                <br/>
                <br/>
                CANCELLATION OR RE-PLANNING OF THE SERVICE BY THE CLIENT
                In case of any modification or cancellation of the reservation by the CLIENT, CAPTURE COLOMBIA TOURS will apply the cancellation rules defined for each of the services, which will be included in the type or adjusted offer. In case they do not define the cancellation conditions in the offers, the following rules will be applied subsidiarily:
                Dending of the tour the following cancellation policies will apply:
                <br/>
                <br/>
                CAPTURE COLOMBIA TOURS
                24 hours before the booking
                <br/>
                <br/>
                THIRD PARTIES
                Grafitti tour, paragliding and coffee tour within 12 hours of time of tour
                <br/>
                <br/>
                Lost city trek Confirmed reservations must be canceled or modified at least one day before, during business hours.
                Reservations canceled the same day will have a 20% penalty on the total value of the tour.
                <br/>
                <br/>
                CANCELLATION AND / OR MODIFICATIONS OF THE CONDITIONS OF THE TRIPS BY CAPTURE COLOMBIA TOURS AFTER INITIATED.
                CAPTURE COLOMBIA TOURS may cancel the service after its start and retain the equipment delivered to the CLIENT in the event that it does not comply with the safety and use standards provided by CAPTURE COLOMBIA TOURS for the respective service, or whenever the CLIENT transgresses the traffic laws, alcohol consumption, physical or psychological aggressions to the members of the trip or to third parties, or when their behavior is dangerous, reckless and / or represents a risk for him or the people who accompany him. In this case THE TOMATOR must compensate CAPTURE COLOMBIA TOURS for the losses suffered and the additional costs that arise, such as early return of equipment and vehicles, hotels, meals, etc. CAPTURE COLOMBIA TOURS will not have to make any refund of the SERVICE payment to the CLIENT.
                CAPTURE COLOMBIA TOURS may modify any of the conditions of execution of the contracted services whenever there are situations beyond its control that require it; including but not limited to: acts of civil disorder; including war, blockades, insurrections, riots, mass protests, and actions of military forces related to or in response to some act of civil disorder, acts, or absence of acts, of the Government and of the Legislative and Judicial branches including laws, orders, regulations, decrees, sentences, legal actions, regulations, renewal or confirmation of permits and licenses, which are carried out either by the Government or any Competent Authority on equipment rental activities or high-risk activities, epidemics, landslides , hurricanes, floods, avalanches, lightning, earthquakes, fire, tsunami, disaster in land, air, rail, river and maritime transport sudden alteration of the state of the roads, weather conditions and other external factors that could limit the services offered; without thereby constituting breach by CAPTURE COLOMBIA TOURS. In any case CAPTURE COLOMBIA TOURS guarantees that the new conditions will maintain the standards initially offered to the extent possible and if it is necessary to cancel the trip CAPTURE COLOMBIA TOURS is not obliged to reimburse the payment of the service.
                <br/>
                <br/>
        
                CAPTURE COLOMBIA TOURS AS AN INTERMEDIATE MARKETER:
                Whenever CAPTURE COLOMBIA TOURS acts in front of the CLIENT as a marketer of a service operated by an affiliated third party, it will be responsible to the CLIENT: (i) to guarantee the existence and provision of the service based on the information previously provided on its page Internet, according to its standards and the information delivered to CAPTURE COLOMBIA TOURS; (ii) to transmit to the CLIENT, in a clear, concise and complete manner, the relevant information so that it knows the characteristics of the service (s) that must be operated by the third party provider and on which CAPTURE COLOMBIA TOURS presumes its truthfulness; (iii) Receive and process any complaint or claim that THE CLIENT submits to the affiliated third party; (iv) to transmit to the CLIENT, in a clear, concise and complete manner, the relevant information so that he knows the characteristics of the service (s) he wishes to acquire; (v) remain at the disposal of the CLIENT to address and resolve any doubt or inconvenience that may arise related to the provision of the service and prior to the signing of the contract between the latter and the affiliated third party; (vi) provide permanent support to the CLIENT, through the appropriate means, providing advice related to the services of the affiliated third parties until the moment of signing the contract; (vii) ensure that the signing of the contract or binding agreement between THE CLIENT and the affiliated third party is carried out; (viii) guarantee the good condition and operation of the equipment used, such as, but not being understood as the only ones: airplanes, planes, buses, vans, land transport, bicycles, motorcycles, boats, boats, among others.
                CAPTURE COLOMBIA TOURS will not be liable for any falsehood or inaccuracy in the information provided by the third party provider or for any incident of any kind that occurs under the contract or that is directly or indirectly related to the execution thereof. If CAPTURE COLOMBIA TOURS comes to 
                accompany the CLIENT to any one of the services provided by the third party provider, this will not be taken as an assumption of responsibility under any circumstances. Based on the foregoing, it is understood that THE TOMOR accepts from now the waiver that CAPTURE COLOMBIA TOURS expressly makes in the terms described above. THE CLIENT will be responsible for informing CAPTURE COLOMBIA TOURS in a timely manner in writing of any incident, complaint or suggestion that arises around the third party provider.
                <br/>
                <br/>
                CHANGE OF WEATHER CONDITIONS FOR THE PARAGLIDING ACTIVITY 
                In case the climatic conditions vary once the CLIENT is at the flying spot, the amount of money paid for the transportation wont be refund.
              </div>
            </Card>
            </div>
        }
        </>
  );
};

export default Terminos;
