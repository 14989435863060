import SectionHero from "components/SectionHero/SectionHero";

interface Reviews {
  titulo: string,
  texto: string,
  url: string
};

const reviewsLista : Reviews[] = [
  {
    titulo: "Attention to Detail",
    texto: "We are a reliable and experienced tour agency, focused on complementing memorable moments and showcasing the best of our culture and our people.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fhand.png?alt=media&token=a02b29e0-a54d-47c2-bbb5-0b869f3c7212"
  },
  {
    titulo: "Lots Of Choices",
    texto: "We offer a wide range of group & private experiences to unmissable and out of the beaten track spots.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fstar.png?alt=media&token=6ee31251-c4fc-436f-bdb3-331939af634d"
  },
  {
    titulo: "Passionate Tour Guides",
    texto: "We are cristal clear that the only way to make you feel our esence is by sharing it with expert local hosts.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fmap.png?alt=media&token=a8a35c82-90a8-40d7-887e-27233befebe7"
  },
  {
    titulo: "Clear Info & Easy Booking",
    texto: "We’ll give you all the details so you can easily figure out what’s the best choice according to your interests. We’ll take care of the rest.",
    url: "https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/iconos_reviews%2Fcell.png?alt=media&token=dd7709d1-dcbc-4e4d-8f84-4a5a42034096"
  }
]
 
function PageHome() {
  return (
    <div 
    className="nc-PageHome relative overflow-hidden"
    style={{
      backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/fondos%2Fback2.png?alt=media&token=33664944-6b94-4d5f-94e6-b7852110fedf)",
      backgroundSize: '101%',
      backgroundRepeat: 'repeat-y', 
    }}
    >
      {/* GLASSMOPHIN */}
     

      <div 
      className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28"
      >
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
     
      </div>
      <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", paddingLeft: "20px"}}
        >
          {reviewsLista.map( el => 
                  <div style={{position: "relative", padding: "40px"}}>
                  <img style={{position: "absolute", width: "80px", top: "0", left: "0"}} src={el.url} alt="Icono" />
                  <div style={{
                    background: "white", 
                    borderRadius: "15px", 
                    width: "270px", 
                    padding: "30px 20px 20px 20px", 
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", 
                    minHeight: "280px",
                    }}>
                      <p style={{color: "black", fontSize: "30px", margin: "10px 0", fontWeight: "600"}}>{el.titulo}</p>
                      <p style={{color: "black", fontSize: "16px"}}>
                          {el.texto}
                      </p>
                  </div>
                  </div>
            )}
        </div>
    </div>
  );
}

export default PageHome;
