import React, { FC, ReactNode,useEffect } from "react";
import { useState } from "react";
import "./SectionHeroArchivePage.css"

import imagePng from "images/hero-right2.png";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
}) => {

  let local_tour =  localStorage.getItem("tour");
  let informacion = localStorage.getItem(local_tour+"")
  let informacionJSON = JSON.parse(informacion+"");

  const [imageTour, setImagetour] = useState(informacionJSON == null  ? null : informacionJSON?.imagenes[0]?.image);
  const [titleTour, setTitleTour] = useState(informacionJSON == null ? "" : informacionJSON.tour.name);
  const [descriptionTour, setDescriptionTour] =  useState(informacionJSON == null ? "" : informacionJSON.tour.description);

  return (
    <div>
      <div>
        <div className="flex-grow" style={{position: "relative"}}>
          <img style={{width:"120%"}} className="w-full" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2Fprincipal_capture_tours.jpg?alt=media&token=cb965b65-3a8e-4a1e-bf64-a61d58c0e1a1" alt="hero" />
          <img className="LogoListing" src="https://firebasestorage.googleapis.com/v0/b/los-patios.appspot.com/o/imagen_principal%2FLogo-Capture.png?alt=media&token=eca452ce-4af9-45ac-a8d4-d916f5d436c6"/>
        </div>
      </div>

      <div 
      className="Font500"
      style={{
        margin: "40px 0 20px", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        flexDirection: "column",
        }}>
        <p style={{marginBottom: "20px"}}>Hop in our smooth rides to live and</p>
        <p>feel what <span style={{color: "#fff741"}}>Colombia</span> is really about!</p>
      </div>

      <div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
